import { initClassificationPlan } from './notice/classificationPlan'
import React from 'react'
import ReactDOM from 'react-dom'
import i18n from './i18n'
import ReadMore from './readMore/ReadMore.jsx'
import ReactHtmlParser from 'react-html-parser'

export const notice = {

    init: function()
    {
        this.addClickEventOnCollapseAllNoticeDetailButton()
        this.initCollapse()
        initClassificationPlan($('#classification-plan-navigation').get(0))
    },

    initCollapse: function()
    {
        $('.read-more').each(function(index, element)
        {
            ReactDOM.render(
                <ReadMore height={50}>
                    {ReactHtmlParser(element.innerHTML)}
                </ReadMore>,
                element
            )
        })

        $('.collapse-await', '.content-notice')
            .toggleClass('collapse-await collapse')
            .collapse({toggle: false})
        $('.content-notice').css('visibility', 'visible')
    },

    addClickEventOnCollapseAllNoticeDetailButton: function()
    {
        $('.collapse-toggle-all', '.content-notice').click(function(e){
            const toggleButton = $(e.currentTarget)
            const toggleButtonAction = toggleButton.find('span')
            const parentRecords = $('.collapse', '.content-notice').not(':last')

            toggleButton.removeClass('show hide')

            $('.collapse:last', 'content-notice').collapse('show')
            if(toggleButton.data('toggled') !== true)
            {
                toggleButton.addClass('hide')
                toggleButtonAction.html(toggleButton.attr('data-parentRecords-fold'))
                $('.collapse', '.content-notice').collapse('show')
            }
            else
            {
                toggleButton.addClass('show')
                toggleButtonAction.html(toggleButton.attr('data-parentRecords-unfold'))
                parentRecords.collapse('hide')
            }

            toggleButton.data('toggled', ! toggleButton.data('toggled'))
        })
    }
}

notice.init()
