import React, { Component } from 'react'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {debounce} from 'lodash'

class ReadMore extends Component
{
    static propType = {
        height: PropTypes.number.isRequired,
        t: PropTypes.func.isRequired,
    }

    constructor(props)
    {
        super(props)

        this.contentRef = React.createRef()
        this.onClick = this.onClick.bind(this)
        this.onContentClick = this.onContentClick.bind(this)
        this.onWindowResize = this.onWindowResize.bind(this)
        this.isContentOverflow = this.isContentOverflow.bind(this)
        this.updateReadMoreDisplay = this.updateReadMoreDisplay.bind(this)
        this.initialContentHeight = null

        this.state = {
            contentNeedsReadMore: false,
            contentDisplayed: false,
        }
    }

    isContentOverflow()
    {
        return this.contentRef.current.scrollHeight > this.props.height
    }

    onClick()
    {
        this.setState({
            contentDisplayed: ! this.state.contentDisplayed,
            height: this.contentRef.current.scrollHeight
        })
    }

    onContentClick()
    {
        if(this.state.contentDisplayed)
        {
            return
        }

        this.onClick()
    }

    onWindowResize()
    {
        this.updateReadMoreDisplay()
    }

    updateReadMoreDisplay()
    {
        if(this.contentRef.current === null)
        {
            return
        }

        let contentNeedsReadMore = false
        let height = this.contentRef.current.scrollHeight

        if(this.isContentOverflow())
        {
            contentNeedsReadMore = true
        }

        if(this.state.contentDisplayed)
        {
            height = this.contentRef.current.scrollHeight
        }

        this.setState({
            contentNeedsReadMore,
            height,
        })
    }

    componentDidMount()
    {
        window.addEventListener('resize', debounce(this.onWindowResize, 500))
        this.updateReadMoreDisplay()
    }

    render()
    {
        let height = this.state.height
        let contentTitle = ''
        if(this.state.contentNeedsReadMore && ! this.state.contentDisplayed)
        {
            height = this.props.height
            contentTitle = this.props.t('readMore:more')
        }

        return (
            <div
                className={'readMore'}
            >
                <div
                    style={{
                        height: height
                    }}
                    className={classNames('multiline', {'closed': this.state.contentNeedsReadMore && ! this.state.contentDisplayed})}
                >
                    <div
                        ref={this.contentRef}
                        onClick={this.onContentClick}
                        title={contentTitle}
                    >
                        {this.props.children}
                    </div>
                </div>
                {
                    this.state.contentNeedsReadMore &&
                    <div className={'viewMore'}>
                        <a onClick={this.onClick}>
                            {
                                this.state.contentDisplayed ? this.props.t('readMore:less'): this.props.t('readMore:more')
                            }
                        </a>
                    </div>
                }
            </div>
        )
    }
}

export default withTranslation()(ReadMore)
