import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import Type from '../Media/Type.jsx'
import { createContentLines } from "../../helpers/BranchContent"

export class BranchLevel extends Component
{
    static propTypes = {
        title: PropTypes.string.isRequired,
        branchContentType: PropTypes.string.isRequired,
        branchContent: PropTypes.object.isRequired,
        isLast: PropTypes.bool.isRequired,
        isOpen: PropTypes.bool.isRequired,
        mediaTypes: PropTypes.array.isRequired,
        t: PropTypes.func.isRequired,
    }

    constructor(props)
    {
        super(props)

        this.buildBranchContent = this.buildBranchContent.bind(this)
        this.renderBranchContent = this.renderBranchContent.bind(this)
        this.onClick = this.onClick.bind(this)
        this.hasContent = this.hasContent.bind(this)
        this.hasContentChanged = this.hasContentChanged.bind(this)
        this.updateLevelState = this.updateLevelState.bind(this)
        this.mediaTypes = this.mediaTypes.bind(this)

        this.state = {
            isOpen: false,
            branchContent: [],
        }

        this.levelElement = React.createRef()
        this.mediaTypesRef = React.createRef()
    }

    buildBranchContent()
    {
        return createContentLines(this.props.branchContentType, this.props.branchContent)
    }

    renderBranchContent()
    {
        if(! this.hasContent())
        {
            return null
        }

        return (
            <div
                className={classNames(
                    'branch-content',
                )}
            >
                {this.state.branchContent}
            </div>
        )
    }

    onClick()
    {
        if(! this.hasContent())
        {
            return
        }

        this.setState({
            isOpen: ! this.state.isOpen,
        })
    }

    hasContent()
    {
        return this.state.branchContent.length > 0
    }

    hasContentChanged(prevProps, prevState)
    {
        return this.state.branchContent.length !== prevState.branchContent.length
    }

    updateLevelState()
    {
        this.setState({
            isOpen: this.props.isOpen || (this.props.isLast && this.hasContent()),
            branchContent: this.buildBranchContent(),
        })
    }

    componentDidMount()
    {
        this.updateLevelState()

        if(this.props.isLast)
        {
            this.levelElement.current.scrollIntoView({
                block: 'center',
                behavior: 'smooth'
            })
        }
    }

    componentDidUpdate(prevProps, prevState)
    {
        if(
            this.props.isOpen === prevProps.isOpen
            && this.props.isLast === prevProps.isLast
            && ! this.hasContentChanged(prevProps, prevState)
        )
        {
            return null
        }

        this.updateLevelState()
    }

    mediaTypes()
    {
        const { mediaTypes } = this.props

        if(mediaTypes.length === 0)
        {
            return null
        }

        return (
            <div
                ref={this.mediaTypesRef}
                className={'media-types'}
            >
                {
                    mediaTypes.map((mediaType, index) => {
                        return <Type key={index} id={mediaType} />
                    })
                }
            </div>
        )
    }

    render()
    {
        return (
            <div
                className={classNames(
                    'level',
                    { 'last': this.props.isLast },
                    { 'hasNoChildren': ! this.hasContent() },
                )}
                ref={this.levelElement}
            >
                <div
                    className={classNames(
                        'level-header',
                        { 'open': this.state.isOpen },
                    )}
                    style={{'paddingRight': this.mediaTypesRef.current && this.mediaTypesRef.current.getBoundingClientRect().width}}
                    onClick={this.onClick}
                >
                    <h2
                        className={'level-title'}
                        title={this.props.title}
                    >
                        {this.props.title}
                    </h2>
                    {
                        this.mediaTypes()
                    }
                </div>
                <div
                    className={classNames('level-content', { 'hidden': ! this.state.isOpen })}
                >
                    {this.renderBranchContent()}
                </div>
            </div>
        )
    }
}

export default withTranslation()(BranchLevel)
