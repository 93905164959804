import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import classNames from "classnames";
import { notification } from '../../../../common/js/notification'
import { Alert } from '../../Alert.jsx'
import copy from 'clipboard-copy'

class CopyUrl extends Component
{
    static propTypes = {
        t: PropTypes.func.isRequired,
    }

    constructor(props)
    {
        super(props)

        this.onClick = this.onClick.bind(this)
    }

    onClick()
    {
        copy(window.location.href)
        notification.success(Alert, this.props.t('actionbar:copy.url.confirm'))
    }

    render()
    {
        const { t } = this.props

        return (
            <div
                key="copyUrl"
                title={t('actionbar:copy.url.title')}
                aria-label={t('actionbar:copy.url.title')}
                onClick={this.onClick}
                data-alert-message={t('actionbar:copy.url.confirm')}
                className='actionbar-action'
            >
                <i className={classNames('fa', 'fa fa-link')} aria-hidden="true"></i>
                <span className="sr-only">{t('actionbar:copy.url.title')}</span>
            </div>
        )
    }
}

export default withTranslation()(CopyUrl)
