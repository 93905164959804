import React, { Component } from 'react'
import superagent from 'superagent'
import PropTypes from 'prop-types'
import Type from './Type.jsx'

export default class List extends Component
{
    static propType = {
        mediaUrl: PropTypes.string.isRequired,
    }

    constructor(props)
    {
        super(props)

        this.state = {
            media: [],
        }
    }

    componentDidMount()
    {
        superagent
            .get(this.props.mediaUrl)
            .then((res) => {
                this.setState({
                    media: res.body,
                })
            })
            .catch(() => {
            })
    }

    render()
    {
        const { media } = this.state

        if(media.length === 0)
        {
            return null
        }

        return (
            <ul className={'media-list'}>
                {
                    media.map((medium, index) => {
                        return <li
                            className={'media-item'}
                            key={index}
                        >
                            <a
                                className={'ellipsed inline'}
                                title={medium.title}
                                href={medium.url}
                                rel={'noopener noreferrer'}
                                target={'_blank'}
                            >
                                <Type key={index} id={medium.mediaType} />
                                {medium.title}
                            </a>
                        </li>
                    })
                }
            </ul>
        )
    }
}
