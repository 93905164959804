export const retrieveInitialCurrentNode = function(node, initialNodeId)
{
    let currentNode = null
    if(node.subLevel.childrenBatch && node.subLevel.childrenBatch.children.length > 0)
    {
        node.subLevel.childrenBatch.children.forEach(node =>
        {
            if(currentNode !== null)
            {
                return
            }

            const foundNode = retrieveInitialCurrentNode(node, initialNodeId)
            if(foundNode !== null)
            {
                currentNode = foundNode
            }
        })

        if(currentNode)
        {
            return currentNode
        }
    }

    if(node.id === initialNodeId)
    {
        currentNode = node
    }

    return currentNode;
}
