import { Loading } from '../../common/js/loading'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import { render, unmountComponentAtNode } from 'react-dom'
import React from 'react'

export const renderer = {

    init: function() {
        this.header()
        this.menu()
        this.scrollUp()
        this.searchFixedHeaderPosition()
        this.smouthScroll()
        this.formSubmitPolyfill()
        this.dropDownList()
        this.modal()
        this.externalVideo()
        this.ellipsis()
        this.tooltip()
        this.fixedActionbar()
    },

    rebuild: function() {
        this.header()
        this.scrollUp()
        this.searchFixedHeaderPosition()
        this.externalVideo()
        this.fixedActionbar()
        this.updateEllipsis()
    },

    onScroll: function() {
        this.scrollUp()
        this.searchFixedHeaderPosition()
        this.fixedActionbar()
    },

    header: function() {
        const headerElement = $('#header')
        if(headerElement)
        {
            headerElement.css('position', 'fixed')
            $('main').css('padding-top', headerElement.height())
        }

        const btnReturnElement = $('.btn-return')
        if(btnReturnElement)
        {
            btnReturnElement.css('position', 'fixed')
            btnReturnElement.css('top', headerElement.height())
            $('main').css('padding-top', headerElement.height() + btnReturnElement.height())
        }
    },

    isHeaderStickyTriggered: function(stickyTop = null) {
        const windowTop = $(window).scrollTop()
        let stickyTopPosition = $('header').height()
        const containerTop = $('main .container').offset().top

        if(stickyTop !== null)
        {
            stickyTopPosition = stickyTop
        }

        const stickyTrigger = containerTop - stickyTopPosition

        return windowTop > stickyTrigger
    },

    scrollUp: function() {
        const footer = $('#footer')
        if (footer.length)
        {
            var footerTop = footer.position().top

            $('#scrollup').css({
                'bottom': '0',
                'position': 'fixed',
                'right': '0',
                'z-index': '10',
            })

            $('#scrollup').removeClass('displayed')
            if (this.isHeaderStickyTriggered()) {
                $('#scrollup').addClass('displayed')
            }

            var scrollUpEnd = $("#scrollup").offset().top + $("#scrollup").outerHeight(true)

            if (scrollUpEnd > footerTop) {
                $('#scrollup').css('bottom', scrollUpEnd - footerTop)
            }
        }
    },

    searchFixedHeaderPosition: function() {
        const searchResultTableHeader = $('#list-container table .fixed-scroll')
        const searchResultTable = $('#list-container .table')
        const searchResultNavigationHeader = $('.navigation')
        const main = $('main')
        const header = $('header')
        const container = $('#display-results main .container')
        const btnReturn = $('.btn-return')

        if(this.isHeaderStickyTriggered( header.height() + searchResultNavigationHeader.height() + btnReturn.height()))
        {
            main.addClass('stickyHeader')
            container.css({
                'margin-top': searchResultNavigationHeader.height(),
                'padding-top': searchResultTableHeader.height(),
            })

            searchResultNavigationHeader
                .addClass('fixed')
                .css('top', header.height() + btnReturn.height())

            searchResultTable.addClass('fixed')
            searchResultTableHeader.css('top', header.height() + searchResultNavigationHeader.height())

            if ($("#form-facets").is(':visible'))
            {
                container.addClass('with-facets')
                searchResultTable.addClass('with-facets')
                searchResultNavigationHeader.addClass('with-facets')
            }
            else
            {
                container.removeClass('with-facets')
                searchResultTable.removeClass('with-facets')
                searchResultNavigationHeader.removeClass('with-facets')
            }
        }
        else
        {
            main.removeClass('stickyHeader')
            container
                .css({
                    'margin-top': 0,
                    'padding-top': 0,
                })

            searchResultNavigationHeader
                .css('top', 0)
                .removeClass('fixed')
                .removeClass('with-facets')

            searchResultTableHeader
                .css('top', 0)
            searchResultTable
                .removeClass('fixed')
                .removeClass('with-facets')
        }
    },

    menu: function() {
        $('nav ul li')
            .mouseenter(this.displaySubmenu)
            .mousemove(this.displaySubmenu)
            .mouseleave(this.hideSubmenu)
    },

    displaySubmenu: function(event) {

        const submenu = $(event.currentTarget).children('.subnav')
        if(submenu === undefined || submenu.get(0) === undefined)
        {
            return
        }

        if(submenu.is(":visible"))
        {
            return
        }

        submenu.show()

        const rect = submenu.get(0).getBoundingClientRect()

        let rectX = rect.x
        if(rectX === undefined && rect.left !== undefined)
        {
            rectX = rect.left
        }

        if((rectX + rect.width) > document.documentElement.clientWidth)
        {
            submenu.addClass('align-right')
        }
    },

    hideSubmenu: function(event) {
        const submenu = $(event.currentTarget).children('.subnav')
        if(submenu === undefined)
        {
            return
        }

        submenu.hide()
    },

    smouthScroll: function() {
        $('a[href^="#"]').click(function() {
            var target = $(this).attr("href")
            if (target === '#')
            {
                return
            }

            $('html, body').animate({
                scrollTop:$(target).offset().top
            }, 'slow')

            return false
        })
    },

    tooltip: function() {
        $('[data-toggle="tooltip"]').tooltip()
    },

    ellipsis: function(context) {
        $('.ellipsis', context).each(function(index, element) {
            $(element).truncate({
                maxHeight: $(element).outerHeight(),
            })
        })
    },

    updateEllipsis: function() {
        $('.ellipsis').each(function(index, element) {
            $(element).truncate('update')
        })
    },

    modal: function() {
        this.modalOnClick()
        this.modalOnShow()
    },

    modalOnClick: function() {
        $('[data-modal]').click(function() {
            $('#' + $(this).attr('data-target')).modal('toggle')
        })
    },

    modalOnShow: function() {
        $('.modal').on('show.bs.modal', function(event) {
            const modal = $(this)
            const url = modal.attr('data-url')
            const container = modal.find('.modal-body').first().get(0)

            if(typeof container !== 'object' || url === undefined)
            {
                return
            }

            render(<MuiThemeProvider><Loading /></MuiThemeProvider>, container)

            $.ajax({
                url
            }).done(function(response) {
                unmountComponentAtNode(container)

                $(container).html(response)
            })
        })
    },

    formSubmitPolyfill: function() {
        $('button[type="submit"][form][data-external-submit]').click(function(event) {
            event.preventDefault()
            const formAttribute = $(event.currentTarget).attr('form')
            $( '#' + formAttribute ).submit()
        })
    },

    dropDownList: function() {
        $(".navigation .select").on("click", function () {
            $(this).toggleClass("active")
            $(this).children( "ul" ).toggleClass("active")
        })

        $(document).on("click", function(event) {
            const clickedElement = event.target
            $(".navigation .select").each(function() {
                if(! $.contains(this, clickedElement))
                {
                    $(this).removeClass("active")
                    $(this).children("ul").removeClass("active")
                }
            })
        })
    },

    externalVideo: function() {
        if($('.section-video').length === 0)
        {
            return
        }

        const ratio = 16/9
        const availableWidth = $('.section-video').first().width()

        $('iframe', $('.section-video')).each(function() {
            $(this).css('width', availableWidth)
            $(this).css('height', availableWidth / ratio)
            $(this).removeClass('hidden')
        })
    },

    fixedActionbar: function() {
        const actionbar = $('div:not(.result-actionbar) > #actionbar')

        if(this.isHeaderStickyTriggered())
        {
            actionbar
                .addClass('fixed')
                .css('top', $('header').height())
        }
        else
        {
            actionbar
                .removeClass('fixed')
                .css('top', 0)
        }
    }
}
