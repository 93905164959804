import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Level from './Level.jsx'
import HiddenLevels from './HiddenLevels.jsx'

class Breadcrumb extends Component
{
    static propTypes = {
        updateCurrentNode: PropTypes.func.isRequired,
        levels: PropTypes.array.isRequired,
    }

    constructor(props)
    {
        super(props)

        this.buildLevels = this.buildLevels.bind(this)
        this.buildLevel = this.buildLevel.bind(this)
        this.renderLevels = this.renderLevels.bind(this)
    }

    buildLevels()
    {
        const levels = this.props.levels
        const numberOfVisibleLevels = 2

        if(levels.length === 0)
        {
            return null
        }

        let hiddenLevelsComponent = null
        if(levels.length > numberOfVisibleLevels + 1)
        {
            hiddenLevelsComponent = <HiddenLevels onLevelClick={this.props.updateCurrentNode} key={'hiddenLevels'} levels={levels.slice(1, levels.length - numberOfVisibleLevels)} />
        }

        const endParts = this.props.levels.slice(Math.max(1, levels.length - numberOfVisibleLevels), levels.length).map((levelData, index) => {
            return this.buildLevel(levelData, index === (numberOfVisibleLevels - 1))
        })

        return [
            this.buildLevel(levels[0]),
            hiddenLevelsComponent,
            [...endParts]
        ]
    }

    buildLevel(levelData, lastLevel = false)
    {
        return <Level
            onClick={() => this.props.updateCurrentNode(levelData)}
            title={levelData.title}
            key={levelData.id}
            ariaCurrent={lastLevel ? 'page' : 'false'}
        />
    }

    renderLevels()
    {
        if(! this.props.levels)
        {
            return null
        }

        return this.buildLevels()
    }

    render()
    {
        return (
            <ul
                ref={this.props.innerRef}
                id={'breadcrumb'}
                className={'col-12'}
            >
                {this.renderLevels()}
            </ul>
        )
    }
}

export default React.forwardRef((props, ref) => <Breadcrumb
    innerRef={ref} {...props}
/>);
