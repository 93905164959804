import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

class ContentLine extends Component
{
    static propType = {
        label: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
    }

    render()
    {

        return (
            <div className={'row level-part'}>
                <label className={'col-3 attribut'} title={this.props.label}>{this.props.label}</label>
                <div className={'col-9 content'}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default withTranslation()(ContentLine)

