import { stats } from './stats/index'
import { cart } from './reservation/cart'

const userProfile = {

    init: function()
    {
        this.addClickEventOnDeletedButton()
        this.bindDeleteBookmarkEvent()
        this.bindClickBookmarkTitle()
        this.toggleButtons()
        this.enableSubmitOnFormChange()

        stats.init()
        cart.init()
    },

    addClickEventOnDeletedButton: function()
    {
        $('#delete-user-profile').click(function(e){
            e.preventDefault()
            const button = e.currentTarget

            const message = $(button).attr('data-message')
            const formTarget = $(button).attr('data-form-target')

            if(confirm(message))
            {
                $('#' + formTarget).submit()
            }
        })
    },

    bindDeleteBookmarkEvent: function()
    {
        $('.delete-bookmark').click(function(e){
            e.preventDefault()
            const button = e.currentTarget

            const formTarget = $(button).attr('data-form-target')

            $('#' + formTarget).submit()
        })
    },

    bindClickBookmarkTitle: function()
    {
        $('.bookmarks .title a').click(function(e) {
            e.preventDefault()
        })

        $('.bookmarks [data-link]').click(function(e) {
            window.open($(this).data('link'), '_blank')
        })
    },

    toggleButtons: function()
    {
        const $forms = $('#user-profile .user-profile-content .user-forms.local-user form')

        $forms.find('input').prop('disabled', true)
        $forms.find('label, .info').addClass('disabled')
        $forms.each(function()
        {
            const $form = $(this)
            $form.find('.toggle-button').click(function()
            {
                $form.find('input:not([type=submit])').prop('disabled', false)
                $form.find('label, .info').removeClass('disabled')
                $form.find('.toggle-display').toggleClass('d-none')
                $form.find('.toggle-empty').val('')
            })
        })
    },

    enableSubmitOnFormChange: function()
    {
        $('.form-control').on('input', function() {
            $(this)
                .closest("form")
                .find("input[type=submit]")
                .first()
                .prop('disabled', false)
        })
    }
}

$(function(){
    userProfile.init()
})
