import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class LoadNodesButton extends Component
{
    static propTypes = {
        depth: PropTypes.number.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired,
        additionalClass: PropTypes.string,
    }


    constructor(props)
    {
        super(props)

        this.onLineClick = this.onLineClick.bind(this)
        this.nodeElement = React.createRef()
    }


    onLineClick(event)
    {
        event.preventDefault()
        event.stopPropagation()

        this.props.onClick()
    }

    render()
    {
        return (
            <li
                onClick={this.onLineClick}
                className={classNames({ 'hidden': ! this.props.isVisible })}
            >
                <div
                    className={classNames(
                        'node',
                        'loadNodesButton',
                        this.props.additionalClass
                    )}
                    ref={this.nodeElement}
                    style={{
                        paddingLeft: this.props.depth * 20
                    }}
                >
                    <a>{this.props.text}</a>
                </div>
            </li>
        )
    }
}

export default LoadNodesButton
