import React from 'react'
import { useSwipeable } from 'react-swipeable'

const KeyboardGestureNavigation = props => {

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => props.onNext(),
        onSwipedRight: () => props.onPrevious(),
    })

    const onKeyPressed = event => {
        if(event.repeat)
        {
            return
        }

        switch(event.key)
        {
            case 'ArrowLeft':
                props.previous()
                break
            case 'ArrowRight':
                props.next()
                break
            case 'Enter':
                if(event.target === event.currentTarget)
                {
                    props.imageRef.current.click()
                }
        }
    }

    return <div
        tabIndex="0"
        onKeyDown={onKeyPressed}
        {...swipeHandlers}
    >
        {props.children}
    </div>
}

export default KeyboardGestureNavigation
