import Cookies from "universal-cookie"
import { initPagination } from '../../common/js/pagination'
import { initMultiselect } from '../../common/js/multiselect/index'

const advancedSearchPage = {
    init: function()
    {
        this.initValidation()
        this.initMultiselect()
        this.initHistoryHandling()
    },

    form: function()
    {
        return $('#form-search-advanced-search')
    },

    initValidation: function()
    {
        const form = this.form()

        if (form.length === 0)
        {
            return
        }

        const input = form.find("input[data-validate-gte]").first()

        $(input.attr('data-validate-gte')).change(function()
        {
            const min = $(this).val() ? $(this).val() : 1

            input.attr("min", min)
        });

        form.find("input[data-validate-required]").change(function()
        {
            const required = $($(this).attr("data-validate-required"))

            if ($(this).val())
            {
                required.attr("required", true)
            }
            else
            {
                required.removeAttr("required")
            }
        }).change()
    },

    initMultiselect: function()
    {
        const multiselects = document.getElementsByClassName('multiselect')

        Array.from(multiselects).forEach((element) => {
            initMultiselect(element)
        })
    },

    initHistoryHandling: function()
    {
        const form = this.form()
        form.submit((e) => {
            const formData = new FormData(form[0])
            formData.delete('formUuid')
            const queryString = new URLSearchParams(formData).toString()

            history.replaceState(null, null, '?' + queryString)
        })
    }
}

const facets = {
    init: function()
    {
        const facetForm = $('#form-facets')
        const facetSection = $('.facets').first()
        const cookies = new Cookies()

        facetForm.find(":checkbox").change(function()
        {
            if($(this).is('.century:not(:checked)'))
            {
                $('.decade').first().prop('checked', false)
            }

            facetForm.submit()
        })

        $('.facets .filter').click(function()
        {
            facetSection.toggleClass('hidden')

            cookies.set('hideFacet', facetSection.hasClass('hidden'), {path: '/'})
        })

        $('.facets .show-more').click(function()
        {
            $(this).hide()
            $(this).parent().children(".show-less").show()

            const termList = $(this).parent().children('ul')
            termList.children().each(function()
            {
                if ($(this).hasClass('hidden'))
                {
                    $(this).removeClass('hidden')
                }
            })
        })

        $('.facets .show-less').click(function()
        {
            $(this).hide()
            $(this).parent().children(".show-more").show()

            const termList = $(this).parent().children('ul')
            termList.children().each(function(index)
            {
                if (index < 5) {
                    return;
                }

                if (!$(this).hasClass('hidden'))
                {
                    $(this).addClass('hidden')
                }
            })
        })
    }
}

$(document).ready(function()
{
    advancedSearchPage.init()
    facets.init()
    initPagination()
})
