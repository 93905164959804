import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { BranchNode } from "./BranchNode.jsx"
import { LeafNode } from "./LeafNode.jsx"
import { LeafData } from "../entities/LeafData"
import { BranchData } from "../entities/BranchData"
import { TreeNodeEntity } from "../entities/TreeNodeEntity"

export class TreeBrowser extends Component
{
    static propTypes = {
        tree: PropTypes.instanceOf(TreeNodeEntity).isRequired,
        currentNodeId: PropTypes.string.isRequired,
        pxIndentSize: PropTypes.number.isRequired,
        pxPaddingSize: PropTypes.number.isRequired,
        onBranchClick: PropTypes.func.isRequired,
        onLeafClick: PropTypes.func.isRequired
    }

    render()
    {
        const nodes = this.createNodeComponents(this.props.tree)

        if(nodes.length <= 0)
        {
            return (null)
        }

        return (<ul>{nodes}</ul>)
    }

    createNodeComponents(treeNode)
    {
        if(treeNode === null)
        {
            return []
        }

        const {pxPaddingSize, pxIndentSize, currentNodeId} = this.props

        const style = { paddingLeft: (pxPaddingSize + treeNode.depth * pxIndentSize) + 'px'}
        const isCurrent = treeNode.id === currentNodeId

        if(treeNode.data instanceof LeafData)
        {
            return [this.createLeafNode(treeNode, style, isCurrent)]
        }

        if(treeNode.data instanceof BranchData)
        {
            const nodeList = [this.createBranchNode(treeNode, style, isCurrent)]

            if(treeNode.data.isOpen)
            {
                treeNode.data.children.forEach(treeNode => {
                    nodeList.push(this.createNodeComponents(treeNode))
                })
            }

            return nodeList
        }
    }

    createLeafNode(treeNode, style, isCurrent)
    {
        return <LeafNode
            key={treeNode.id}
            treeNode={treeNode}
            style={style}
            isCurrent={isCurrent}
            onClick={this.props.onLeafClick}
        />
    }

    createBranchNode(treeNode, style, isCurrent)
    {
        return <BranchNode
            key={treeNode.id}
            treeNode={treeNode}
            style={style}
            isCurrent={isCurrent}
            onClick={this.props.onBranchClick}
        />
    }
}
