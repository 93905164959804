import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import classNames from "classnames";

class Mail extends Component
{
    static propTypes = {
        t: PropTypes.func.isRequired,
        siteName: PropTypes.string.isRequired,
        pageTitle: PropTypes.string.isRequired,
        shareMailBody: PropTypes.string.isRequired,
    }

    render()
    {
        const { t, siteName, pageTitle, shareMailBody } = this.props

        const subject = encodeURI(`${siteName} - ${pageTitle.trim()}`)
        const body = shareMailBody.split("\n").join("%0D%0A")

        return (
            <a
                key="shareMail"
                rel="noopener noreferrer"
                target="_blank"
                title={t('actionbar:share.mail')}
                aria-label={t('actionbar:share.mail')}
                className='actionbar-action'
                href={`mailto:?subject=${subject}&body=${body}`}
            >
                <i className={classNames('fa', 'fa-envelope')} aria-hidden="true"/>
                <span className="sr-only">{t('actionbar:share.mail')}</span>
            </a>
        )
    }
}

export default withTranslation()(Mail)
