import React, { Component } from 'react'
import PropTypes from 'prop-types'
import superagent from 'superagent'
import classNames from 'classnames'
import LoadedIndexationsPerDayOnPeriod from './RecentIndexationsPerDay.jsx'
import FormFillingPercentage from './FormFillingPercentage.jsx'
import Indicator from './Indicator.jsx'

export class Tile extends Component
{
    static propTypes = {
        title: PropTypes.string,
        icon: PropTypes.string,
        graph: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    }

    constructor(props)
    {
        super(props)

        this.loadIndicator = this.loadIndicator.bind(this)
        this.graphs = {
            'indicator': Indicator,
            'formFillingPercentage': FormFillingPercentage,
            'indexationPerDayOnPeriod': LoadedIndexationsPerDayOnPeriod,
        }

        this.state = {
            graphData: null,
        }
    }

    async loadIndicator()
    {
        await superagent
            .get(this.props.url)
            .set('X-Requested-With', 'XMLHttpRequest')
            .then((res) => {
                this.setState({graphData: res.body})
            })
    }

    componentWillMount()
    {
        this.loadIndicator()
    }

    render()
    {
        const {title, icon, graph, id, ...rest} = this.props

        if(! (graph in this.graphs))
        {
            console.log(`Graph not found for ${graph}`)
            return null
        }

        const GraphComponent = this.graphs[graph]

        return (
            <div id={id} className={classNames('tile-container', `tile-${graph}`)}>
                <div className={'graph-title'}>
                    { icon && <i className={classNames(icon, 'graph-icon')} aria-hidden="true"></i> }
                    <span>{title}</span>
                </div>

                <div className={classNames(`graph-${graph}`, 'graph-data')}>
                {
                        <GraphComponent data={this.state.graphData} {...rest} />
                }
                </div>
            </div>
        )
    }
}
