import Modernizr from "./modernizr"
import Cookies from 'universal-cookie'

export const oldBrowsers = {

    /**
     *  Filters have to be declared in /.modernizrrc
     */
    filters: [
        'formattribute',
        'es6object',
        'flexbox',
        'cssfilters',
        'canvas',
        'video',
        'audio'
    ],

    init: function()
    {
        this.addEventClickOldBrowsersButton()
        this.displayDisclaimer()
    },

    addEventClickOldBrowsersButton: function()
    {
        $('#old-browsers-accept').click(function(){
            const cookie = new Cookies()
            cookie.set('oldBrowsersAccepted', true, { path: '/' })
            $("#old-browsers").slideUp()
        })
    },

    displayDisclaimer: function()
    {
        if((new Cookies()).get('oldBrowsersAccepted') === 'true')
        {
            return
        }

        if(this.isBrowserObsolete())
        {
            $('#old-browsers')
                .css('top', $('#header').height())
                .show()
        }
    },

    isBrowserObsolete: function()
    {
        let isBrowserObsolete = false

        this.filters.forEach(function(filter){
            if(Modernizr[filter] != true)
            {
                isBrowserObsolete = true
            }
        })

        return isBrowserObsolete
    }
}
