import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import BranchLevel from './BranchLevel.jsx'
import { withTranslation } from 'react-i18next'

class Branch extends Component
{
    static propTypes = {
        currentNode: PropTypes.object,
        levels: PropTypes.array.isRequired,
        t: PropTypes.func.isRequired,
    }

    constructor(props)
    {
        super(props)

        this.buildLevels = this.buildLevels.bind(this)
        this.renderLevels = this.renderLevels.bind(this)
        this.toggleAllLevels = this.toggleAllLevels.bind(this)

        this.state = {
            allLevelsAreOpen: false,
        }
    }

    buildLevels()
    {
        return this.props.levels.map((levelData, index) => {
            return <BranchLevel
                isOpen={this.state.allLevelsAreOpen}
                isLast={index + 1 === this.props.levels.length}
                title={levelData.title}
                branchContentType={levelData.branchContentType}
                branchContent={levelData.branchContent || []}
                key={levelData.id}
                mediaTypes={levelData.mediaTypes}
            />
        })
    }

    toggleAllLevels()
    {
        this.setState({
            allLevelsAreOpen: ! this.state.allLevelsAreOpen
        })
    }

    renderLevels()
    {
        if(! this.props.levels)
        {
            return null
        }

        return (
            <div className={'levels'}>
                <div className={'level toggle-all-levels'} onClick={this.toggleAllLevels}>
                    <div className={classNames(
                        'level-header',
                        {'open': this.state.allLevelsAreOpen}
                    )}>
                        <div className={'level-title'}>
                            {
                                this.state.allLevelsAreOpen ?
                                    this.props.t('classificationBrowsing:branch.levels.toggle.fold') :
                                    this.props.t('classificationBrowsing:branch.levels.toggle.unfold')
                            }
                        </div>
                    </div>
                </div>
                {this.buildLevels()}
            </div>
        )
    }

    render()
    {
        return (
            <div id={'classification-branch'} className={'col'}>
                {this.renderLevels()}
            </div>
        )
    }
}

export default withTranslation()(Branch)
