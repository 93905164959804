import React from 'react'
import ReactDOM from 'react-dom'
import i18n from './i18n'
import Actionbar from './components/Actionbar.jsx'

export const addActionbar = (element) => {
    if(element == null)
    {
        return
    }

    const {
        bookmarkUrl,
        bookmarkData,
        bookmarkListUrl,
        loggedIn,
        autoDisplay,
        print,
        bookmarked,
        siteName,
        pageTitle,
        shareMailBody,
        maintenance,
        social
    } = element.dataset

    ReactDOM.render(
        <Actionbar
            bookmarkUrl={bookmarkUrl}
            bookmarkData={bookmarkData && JSON.parse(bookmarkData)}
            bookmarkListUrl={bookmarkListUrl}
            loggedIn={loggedIn === '1'}
            autoDisplay={autoDisplay === '1'}
            social={social === '1'}
            print={print === '1'}
            bookmarked={bookmarked === '1'}
            siteName={siteName}
            pageTitle={pageTitle}
            shareMailBody={shareMailBody}
            maintenance={maintenance === '1'}
        />,
        element
    )
}
