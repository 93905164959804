import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

import PropTypes from 'prop-types'

class Pagination extends Component
{
    static propTypes = {
        position: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
        isEnabled: PropTypes.bool.isRequired,
        url: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
    }

    render()
    {
        const { position, type, isEnabled, url, t } = this.props

        if(! isEnabled)
        {
            return null
        }

        return (
            <a
                href={url}
                className={classNames('chrono-pagination', 'btn', 'btn-secondary', `chrono-${type}`)}
                style={{ 'left': position }}
            >{t(`chrono:pagination.${type}`)}</a>
        )
    }
}

export default withTranslation()(Pagination)
