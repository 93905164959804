import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { RootNode } from './Nodes/RootNode.jsx'
import { retrieveInitialCurrentNode } from '../../helpers/Node'

export default class Tree extends Component
{
    static propTypes = {
        partialTree: PropTypes.object.isRequired,
        updateCurrentNode: PropTypes.func.isRequired,
        style: PropTypes.object.isRequired,
    }

    constructor(props)
    {
        super(props)

        this.state = {
            node: null
        }
    }

    componentDidMount()
    {
        this.props.updateCurrentNode(retrieveInitialCurrentNode(this.props.partialTree, this.props.initialNodeId))
        this.setState({node: this.props.partialTree})
    }

    render()
    {
        if(this.state.node === null)
        {
            return null
        }

        return (
            <ul
                id={'classification-tree'}
                style={this.props.style}
            >
                <RootNode
                    node={this.props.partialTree}
                    depth={0}
                    updateCurrentNode={this.props.updateCurrentNode}
                    goToParentNode={() => {}}
                    goToPreviousNode={() => {}}
                    goToNextNode={() => {}}
                    updateParentLastDescendent={() => {}}
                    currentNode={this.props.currentNode}
                    isCurrentNodeDisplayed={true}
                    areChildNodesDisplayed={true}
                    key={'root'}
                />
            </ul>
        )
    }
}
