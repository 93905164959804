export const cart = {
    init: function() {
        const selectAllArticles = $("#select-all-articles")
        const articleCheckboxes = $("input[name=articles\\[\\]]");
        const deleteButton = $("#cart-delete-articles");

        selectAllArticles.click(function() {
            articleCheckboxes.prop("checked", selectAllArticles.prop("checked"))
        })

        deleteButton.click(function(e) {
            if (! articleCheckboxes.is(":checked")) {
                e.stopImmediatePropagation()
            }
        })
    }
}
