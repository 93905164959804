import i18n from '../i18n'
import * as translations from './locales'
import * as readMoreTranslations from '../readMore/locales'

const addResourceBundle = function(translations, name){
    for(var lng in translations) {
       i18n.addResourceBundle(lng, name, translations[lng])
    }
}

addResourceBundle(translations, 'classificationBrowsing')
addResourceBundle(readMoreTranslations, 'readMore')

export default i18n
