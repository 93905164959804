import React from 'react'
import ReactDOM from 'react-dom'
import i18n from './i18n'
import { Tile } from './components/Tile.jsx'

export const stats = {

    init() {
        $('.stats-tile').each(function(){
            stats.addTile(this)
        })
    },

    addTile(element)
    {
        const {
            title,
            icon,
            graph,
            id,
            url,
        } = element.dataset

        ReactDOM.render(
            <Tile
                title={title}
                id={id}
                graph={graph}
                icon={icon}
                url={url}
            />,
            element
        )
    }
}
