import React from 'react'
import { Parent } from '../Nodes/Parent.jsx'
import { Leaf } from '../Nodes/Leaf.jsx'

export class NodeFactory
{
    createFromNodeData(node)
    {
        switch(node.levelType)
        {
            case 'parent':
            case 'standaloneParent':
                return Parent

            case 'leaf':
                return Leaf

            default:
                throw `Invalid node type. Expected : parent, standaloneParent, leaf. Given : ${node.levelType}`
        }
    }
}
