import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import superagent from 'superagent'
import { debounce } from 'lodash'
import SplitPane from 'react-split-pane'
import Tree from './Tree/Tree.jsx'
import Branch from './Branch/Branch.jsx'
import Breadcrumb from './Breadcrumb/Breadcrumb.jsx'
import responsiveMode from '../../../../common/js/responsiveMode'

class Browser extends Component
{
    static propTypes = {
        partialTree: PropTypes.object.isRequired,
        updateCurrentUrl: PropTypes.func.isRequired,
        id: PropTypes.string.isRequired,
        responsiveMode: PropTypes.func.isRequired,
        onUpdate: PropTypes.func.isRequired,
        closeDisclaimer: PropTypes.func.isRequired,
        isDisclaimerOpen: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
    }

    constructor(props)
    {
        super(props)

        this.treeComponent = this.treeComponent.bind(this)
        this.treeComponentStyle = this.treeComponentStyle.bind(this)
        this.headingHeight = this.headingHeight.bind(this)
        this.closeDisclaimer = this.closeDisclaimer.bind(this)
        this.updateCurrentNode = this.updateCurrentNode.bind(this)
        this.updateResponsiveMode = this.updateResponsiveMode.bind(this)
        this.handleWindowResize = this.handleWindowResize.bind(this)
        this.dispatchWindowResize = this.dispatchWindowResize.bind(this)
        this.updateAncestors = debounce(() =>
                superagent
                    .get(this.state.currentNode.ancestorsUrl)
                    .then((res) => {
                        this.setState({
                            levels: res.body,
                        })
                    }),
            50
        ).bind(this)

        this.breadcrumbRef = React.createRef()
        this.notOptimizedRef = React.createRef()

        this.state = {
            currentNode: null,
            levels: [],
            responsiveMode: props.responsiveMode(),
            isDisclaimerOpen: props.isDisclaimerOpen(),
        }
    }

    treeComponent()
    {
        return <Tree
            partialTree={this.props.partialTree}
            updateCurrentNode={this.updateCurrentNode}
            initialNodeId={this.props.id}
            currentNode={this.state.currentNode}
            style={this.treeComponentStyle()}
        />
    }

    treeComponentStyle()
    {
        if(this.state.responsiveMode.atLeast(responsiveMode.comfort()))
        {
            return {}
        }

        return {
            paddingTop: this.headingHeight()
        }
    }

    headingHeight()
    {
        let headingHeight = 0
        if(this.breadcrumbRef.current)
        {
            headingHeight = this.breadcrumbRef.current.getBoundingClientRect().height
        }

        if(this.state.isDisclaimerOpen && this.notOptimizedRef.current)
        {
            headingHeight += this.notOptimizedRef.current.getBoundingClientRect().height
        }

        return headingHeight
    }

    closeDisclaimer()
    {
        this.props.closeDisclaimer()
        this.setState({
            isDisclaimerOpen: false,
        })
    }

    updateCurrentNode(node)
    {
        if(! node)
        {
            return
        }

        this.props.updateCurrentUrl(node)

        this.setState({
            currentNode: node,
        })
    }

    updateResponsiveMode()
    {
        this.setState({
            responsiveMode: this.props.responsiveMode()
        })
    }

    handleWindowResize()
    {
        this.updateResponsiveMode()
        window.addEventListener('resize', debounce(this.updateResponsiveMode, 500))
    }

    dispatchWindowResize()
    {
        window.dispatchEvent(new Event('resize'))
    }

    componentDidMount()
    {
        this.handleWindowResize()
    }

    componentWillUnmount()
    {
        window.removeEventListener('resize', debounce(this.updateResponsiveMode, 500))
    }

    componentDidUpdate(prevProps, prevState)
    {
        this.props.onUpdate()

        const { currentNode } = this.state

        if(! currentNode || currentNode === prevState.currentNode)
        {
            return
        }

        this.updateAncestors()
    }

    render()
    {
        return (
            <div id={'classification-browser'} className={'row'}>
                <div id={'heading'}>
                    {
                        ! this.state.responsiveMode.atLeast(responsiveMode.comfort()) &&
                        this.state.isDisclaimerOpen &&
                        <div
                            id={'not-optimized'}
                            onClick={this.closeDisclaimer}
                            ref={this.notOptimizedRef}
                        >
                            <span className={'close-button'}></span>
                            { this.props.t('classificationBrowsing:notOptimized') }
                        </div>
                    }
                    <Breadcrumb
                        ref={this.breadcrumbRef}
                        levels={this.state.levels}
                        updateCurrentNode={this.updateCurrentNode}
                    />
                </div>
                {
                    this.state.responsiveMode.atLeast(responsiveMode.comfort()) ?
                        <SplitPane
                            split="vertical"
                            minSize={'20%'}
                            defaultSize={'62%'}
                            maxSize={'80%'}
                            className={"row"}
                            style={{paddingTop: this.headingHeight()}}
                            onChange={this.dispatchWindowResize}
                        >
                            {this.treeComponent()}
                            <Branch
                                currentNode={this.state.currentNode}
                                levels={this.state.levels}
                            />
                        </SplitPane>
                        :
                        this.treeComponent()
                }
            </div>
        )
    }
}

export default withTranslation()(Browser)
