import React from 'react'
import ReactDOM from 'react-dom'
import { TimelineEventsFactory } from './TimelineEventsFactory'
import i18n from './i18n'
import Pagination from './Pagination.jsx'
import '@knight-lab/timelinejs/dist/js/timeline'
import Cookies from 'universal-cookie'
import { reservations } from '../reservation'
import { renderer } from '../renderer'

function loadData(url)
{
    return $.ajax({ url })
}

async function changeEvent(event, eventUrl)
{
    const urlParams = event.unique_id.split('/')
    if(! Array.isArray(urlParams) || urlParams.length !== 2)
    {
        return
    }

    const recordUrlWithParams = eventUrl.replace('@type@', urlParams[0]).replace('@uuid@', urlParams[1])
    const targetId = event.unique_id.replace('/', '\\/')
    const target = $(`#${targetId} .tl-text-content`, '#chrono')
    const record = await loadData(recordUrlWithParams)

    if(target.length === 1)
    {
        target.html(record)
        reservations.init()
        renderer.ellipsis(`#${targetId}`)
    }
}

function addPaginationLink(container, position, type, isEnabled, url)
{
    const paginationContainer = retrievePaginationContainer(container, type)

    ReactDOM.render(
        <Pagination
            position={position}
            isEnabled={isEnabled}
            url={url}
            type={type}
        />,
        paginationContainer
    )
}

function retrievePaginationContainer(container, type)
{
    const paginationElement = $('.tl-timenav-slider-pagination.pagination-'+type)
    if(paginationElement.length === 0)
    {
        const paginationContainer = document.createElement('div')
        $(paginationContainer).addClass('tl-timenav-slider-pagination').addClass('pagination-'+type)
        container.append(paginationContainer)

        return paginationContainer
    }

    return paginationElement.get(0)
}

function addPagination(pagination)
{
    const container = $('.tl-timenav-slider-background', '#chrono')
    const prevPosition = $('.tl-timemarker:first-child', '#chrono').position().left - 300
    const nextPosition = $('.tl-timemarker:last-child', '#chrono').position().left + 150

    addPaginationLink(container, prevPosition, 'prev', pagination.hasPreviousPage === '1', updateChronoPageUrlParams(pagination.previousPage))
    addPaginationLink(container, nextPosition, 'next', pagination.hasNextPage === '1', updateChronoPageUrlParams(pagination.nextPage))
}

function updateChronoPageUrlParams(page)
{
    const url = new URL(window.location)
    const params = new URLSearchParams(url.search.slice(1))
    params.set('page', page)

    return `${window.location.origin}${window.location.pathname}?${params.toString()}`
}

async function createChrono(element)
{
    const {
        content,
        eventUrl,
        localePath,
        hasNextPage,
        hasPreviousPage,
        nextPage,
        previousPage,
    } = element.dataset

    const data = JSON.parse(content)

    if(! Array.isArray(data) || data.length === 0)
    {
        return noResult()
    }

    if((new Cookies()).get('chronoInfoClosed') !== 'true')
    {
        $('#chrono-info').show();
    }

    const timelineEvents = (new TimelineEventsFactory(thumbnails(element.dataset))).create(data)
    if(timelineEvents.length === 0)
    {
        return noResult()
    }

    const timeline = new TL.Timeline(element, {events: timelineEvents}, {
        timenav_position: 'top',
        language: localePath,
        font: null,
    })

    setTimeout(addPagination, 1000, { hasNextPage, nextPage, hasPreviousPage, previousPage })

    timeline.on('change', (event) => {
        changeEvent(event, eventUrl)
    })

    timeline.on('zoom_in', (event) => {
        setTimeout(addPagination, 1000, { hasNextPage, nextPage, hasPreviousPage, previousPage })
    })

    timeline.on('zoom_out', (event) => {
        setTimeout(addPagination, 1000, { hasNextPage, nextPage, hasPreviousPage, previousPage })
    })
}

function thumbnails(dataset)
{
    try
    {
        return JSON.parse(dataset.thumbnails)
    }
    catch(e)
    {
        return {}
    }
}

function noResult()
{
    $('.no-result').show()
}

$(function(){
    $('#chrono-info-close').click(function(){
        const cookie = new Cookies()
        cookie.set('chronoInfoClosed', true, { path: '/' })
        $("#chrono-info").slideUp()
    })

    $('#chrono').each(function(){
        createChrono(this)
    })
})
