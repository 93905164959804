import React, { Component } from 'react'
import CircularProgress from 'material-ui/CircularProgress'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'

class Loading extends Component
{
    render()
    {
        return <MuiThemeProvider>
            <CircularProgress mode="indeterminate" size={50}/>
        </MuiThemeProvider>
    }
}

export default Loading
