import React, { Component } from 'react'
import PropTypes from 'prop-types'

export class Alert extends Component {

    static propTypes = {
        type: PropTypes.string.isRequired,
        message: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.object,
        ]).isRequired
    }

    render() {
        const { type, message } = this.props

        return (
            <div className={`alert alert-${type} alert-dismissible fade show`} role="alert">
                {message}
                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
        )
    }
}
