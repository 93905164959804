import React from 'react'
import { Parent } from './Parent.jsx'

export class RootNode extends Parent
{
    close() {}

    async onKeyDown(event)
    {
        await super.onKeyDown(event)

        if(event.repeat)
        {
            return
        }

        switch(event.key)
        {
            case 'Home':
                this.props.updateCurrentNode(this.state.childNodes[0])
                break
            case 'End':
                this.props.updateCurrentNode(this.state.lastDescendent)
                break
        }
    }
}
