import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Level extends Component
{
    static propTypes = {
        title: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        ariaCurrent: PropTypes.string,
    }

    render()
    {
        const { title, onClick, ariaCurrent } = this.props

        return (
            <li className={'breadcrumb-level'}>
                <div
                    className={'breadcrumb-level-link'}
                    onClick={onClick}
                    title={title}
                    aria-current={ariaCurrent ?? false}
                >{title}
                </div>
            </li>
        )
    }
}
