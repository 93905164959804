import Cookies from 'universal-cookie'

export const rgpd = {

    init: function()
    {
        this.addClickEventOnRgpdUnderstandInfoButton()
        this.invalidateConsents()
        this.displayRgpdInfo()
    },

    invalidateConsents: function()
    {
        const invalidateConsentData = $('#rgpd-infos').data("invalidationdateofconsents")
        if (invalidateConsentData === undefined ) {
            return
        }

        const cookie = new Cookies()
        const rgpdAcceptedCookie = cookie.get('rgpdAccepted')

        if(new Date(rgpdAcceptedCookie) < new Date(invalidateConsentData) || rgpdAcceptedCookie === "true")
        {
            cookie.remove('rgpdAccepted', { path: '/'})
        }
    },

    displayRgpdInfo: function()
    {
        if((new Cookies()).get('rgpdAccepted') === undefined)
        {
            $('#rgpd-infos').show();
        }
    },

    addClickEventOnRgpdUnderstandInfoButton: function()
    {
        $('#rgpd-infos-understand').click(function(){
            const cookie = new Cookies()

            // 13 mois selon la CNIL (https://www.cnil.fr/sites/default/files/typo/document/Configuration_piwik.pdf)
            const maxAge = 33696000
            cookie.set('rgpdAccepted', new Date(), { path: '/', maxAge: maxAge })
            $("#rgpd-infos").slideUp()
        })
    },
}
