import { initConfirmation } from '../../common/js/confirmation'

$(document).ready(() => {
    initConfirmation(null, {
        btnClasses: {
            cancel: 'btn-secondary',
            confirm: 'btn-primary',
        }
    })
})
