import React from 'react'
import ReactDOM from 'react-dom'
import Browser from './components/Browser.jsx'
import i18n from './i18n'
import responsiveMode from '../../../common/js/responsiveMode'
import Cookies from 'universal-cookie'
import Button from './components/FooterToggleButton/Button.jsx'

export const initClassificationBrowsing = function(element)
{
    if(! element)
    {
        return
    }

    const {
        id,
        partialTree,
    } = element.dataset

    initFooterDisplayState()
    addToggleFooterButton()
    displayFooter()

    const partialTreeData = JSON.parse(partialTree)

    ReactDOM.render(
        <Browser
            updateCurrentUrl={updateClassificationBrowsingCurrentUrl}
            id={id}
            partialTree={partialTreeData}
            responsiveMode={retrieveResponsiveMode}
            closeDisclaimer={closeDisclaimer}
            isDisclaimerOpen={isDisclaimerOpen}
            onUpdate={displayFooter}
        />,
        element
    )
}

export const addToggleFooterButton = function()
{
    const footerHideButtonElement = document.createElement("div")
    $('body').prepend(footerHideButtonElement)

    ReactDOM.render(
        <Button
            onClick={toggleFooterDisplayState}
            footerHeight={retrieveFooterHeight}
            isOpen={isToggleFooterOpen}
            isDisplayable={isFooterTogglable}
        />,
        footerHideButtonElement
    )
}

export const cookiesRepository = new Cookies()

export const isToggleFooterOpen = function()
{
    return cookiesRepository.get('footerDisplayState') === 'true'
}

export const toggleFooterDisplayState = function()
{
    cookiesRepository.set('footerDisplayState', cookiesRepository.get('footerDisplayState') !== 'true', { path: '/' })
    displayFooter()
}

export const initFooterDisplayState = function()
{
    if(cookiesRepository.get('footerDisplayState') === undefined)
    {
        cookiesRepository.set('footerDisplayState', false, { path: '/' })
    }
}

export const displayFooter = function()
{
    $('#footer').show()

    let footerHeight = retrieveFooterHeight()

    if(! isFooterTogglable())
    {
        $('#classification-browsing main').css({
            'min-height': `calc(100% - ${footerHeight}px)`,
            'height': 'auto',
        })
        return
    }

    if(! isToggleFooterOpen())
    {
        footerHeight = 0
        $('#footer').hide()
    }

    $('#classification-browsing main').css({
        'height': `calc(100% - ${footerHeight}px)`,
        'min-height': 'unset',
    })
}

export const isFooterTogglable = function()
{
    return retrieveResponsiveMode().atLeast(responsiveMode.comfort())
}

export const retrieveFooterHeight = function()
{
    return Math.round($('#footer').height())
}

export const closeDisclaimer = function()
{
    cookiesRepository.set('classificationBrowsingDisclaimerDisplayState', false, { path: '/' })
}

export const isDisclaimerOpen = function()
{
    return cookiesRepository.get('classificationBrowsingDisclaimerDisplayState') !== 'false'
}

export const retrieveResponsiveMode = function()
{
    if(responsiveMode.isIndispensable(window.innerWidth))
    {
        return responsiveMode.indispensable()
    }

    if(responsiveMode.isEssential(window.innerWidth))
    {
        return responsiveMode.essential()
    }

    return responsiveMode.comfort()
}

export const updateClassificationBrowsingCurrentUrl = function(node)
{
    if(window.location.pathname === node.arkUrl)
    {
        return
    }

    window.history.replaceState({}, node.title, node.arkUrl)
}

$(function(){
    initClassificationBrowsing($('#classification-browsing-container').get(0))
})
