import { Map } from './map'
import Cookies from 'universal-cookie'
import { Loading } from '../../../common/js/loading'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import ReactDOM from 'react-dom'
import React from 'react'

function updateUrl(mapBounds)
{
    const pageUrl = new URL(window.location.href)
    pageUrl.searchParams.set('mapBounds', JSON.stringify(mapBounds))
    window.history.replaceState({}, document.title, pageUrl.href)
}

function updateFacets(mapBounds)
{
    $('#form-facets input[name="mapBounds"]').val(JSON.stringify(mapBounds))
}

function mapInfo()
{
    if((new Cookies()).get('mapInfoClosed') !== 'true')
    {
        $('#map-info').show();
    }

    $('#map-info-close').click(function(){
        const cookie = new Cookies()
        cookie.set('mapInfoClosed', true, { path: '/' })
        $("#map-info").slideUp()
    })
}

async function initMap(element)
{
    if(! (element instanceof HTMLElement))
    {
        return
    }

    const {
        documentsUrl,
        documentUrl,
        localeSearchInArea,
        localeCenterMap,
    } = element.dataset

    const map = new Map(element, documentsUrl, documentUrl, localeSearchInArea, localeCenterMap)
    try {
        return map.reloadFromBounds(JSON.parse($('input[name="mapBounds"]').val()))
    } catch (e) {}

    map.load()
}

function InitEvents()
{
    document.addEventListener('updateMapBounds', (event) => {
        const mapBounds = event.detail.mapBounds

        updateUrl(mapBounds)
        updateFacets(mapBounds)
    })

    document.addEventListener('documentsLoaded', (event) => {
        $('.result-page .result-part-count').html(event.detail.documents.length)
    })

    document.addEventListener('documentsFound', (event) => {
        mapInfo()
    })

    document.addEventListener('noDocumentsFound', (event) => {
        $('#map-info').hide()
        $('#markersLoading').remove()
        $('.no-result').show()
    })

    document.addEventListener('markersLoaded', (event) => {
        $('#markersLoading').remove()
    })

    document.addEventListener('markersLoading', (event) => {
        const markersLoading = document.createElement('div')

        $(markersLoading).attr('id', 'markersLoading')
        $('.map-container').prepend(markersLoading)
        ReactDOM.render(<MuiThemeProvider><Loading /></MuiThemeProvider>, markersLoading)
    })
}

$(function(){
    initMap($('#map').get(0))
    InitEvents()
})
