import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { NodeFactory } from './factory/NodeFactory'
import { withTranslation } from 'react-i18next'
import LoadNodesButton from "./LoadNodesButton.jsx"

class Node extends Component
{
    static propTypes = {
        node: PropTypes.object,
        nextNode: PropTypes.object,
        depth: PropTypes.number.isRequired,
        onClick: PropTypes.func.isRequired,
        updateCurrentNode: PropTypes.func.isRequired,
        currentNode: PropTypes.object,
        isCurrentNodeDisplayed: PropTypes.bool.isRequired,
        areChildNodesDisplayed: PropTypes.bool,
        hasPrevious: PropTypes.bool,
        onPrevious: PropTypes.func,
        hasNext: PropTypes.bool,
        onNext: PropTypes.func,
    }

    nodeFactory

    constructor(props)
    {
        super(props)

        this.buildChildNodes = this.buildChildNodes.bind(this)
        this.renderChildren = this.renderChildren.bind(this)
        this.onLineClick = this.onLineClick.bind(this)
        this.onChevronClick = this.onChevronClick.bind(this)

        this.nodeFactory = new NodeFactory()

        this.articleCount = React.createRef()
        this.nodeElement = React.createRef()

        this.articleCountWidth = 0
    }

    buildChildNodes(childNodes)
    {
        const {
            depth,
            updateCurrentNode,
            currentNode,
            areChildNodesDisplayed,
            hasNext,
            onNext,
            hasPrevious,
            onPrevious
        } = this.props

        let children = [];

        children.push(Object.entries(childNodes).map(([id, childNode], index) =>
        {
            const NodeComponent = this.nodeFactory.createFromNodeData(childNode)
            const previousChild = childNodes[index - 1]
            const nextChild = childNodes[index + 1]

            const previousNode = previousChild ? (previousChild.lastDescendent || previousChild) : this.props.node
            const nextNode = nextChild || this.props.nextNode

            return <NodeComponent
                goToParentNode={() => updateCurrentNode(this.props.node)}
                goToPreviousNode={() => ! previousChild && hasPrevious ? onPrevious() : updateCurrentNode(previousNode)}
                goToNextNode={() => ! nextChild && hasNext ? onNext() : nextNode && updateCurrentNode(nextNode)}
                nextNode={nextNode}
                node={childNode}
                depth={depth + 1}
                updateCurrentNode={updateCurrentNode}
                updateParentLastDescendent={! nextChild ? this.props.updateLastDescendent : () => this.forceUpdate()}
                currentNode={currentNode}
                isCurrentNodeDisplayed={areChildNodesDisplayed}
                areChildNodesDisplayed={true}
                key={childNode.id}
            />
        }))

        return children
    }

    renderChildren(childNodes)
    {
        const {
            depth,
            hasPrevious,
            onPrevious,
            hasNext,
            onNext,
            node,
            areChildNodesDisplayed,
            t
        } = this.props

        if(! childNodes)
        {
            return null
        }

        const childNodesRendered = this.buildChildNodes(childNodes)

        return <ul>
            <LoadNodesButton
                depth={depth + 1}
                isVisible={hasPrevious && areChildNodesDisplayed}
                onClick={onPrevious}
                text={t('classificationBrowsing:tree.node.displayPrevious')}
                additionalClass={'previous'}
                key={node.id + 'previousChildren'}
            />
            {childNodesRendered}
            <LoadNodesButton
                depth={depth + 1}
                isVisible={hasNext && areChildNodesDisplayed}
                onClick={onNext}
                text={t('classificationBrowsing:tree.node.displayNext')}
                additionalClass={'next'}
                key={node.id + 'nextChildren'}
            />
        </ul>
    }

    onLineClick(event)
    {
        event.preventDefault()
        event.stopPropagation()

        if(this.isLink(this.props.node))
        {
            window.open(this.props.node.arkUrl, "_blank")
            return
        }

        this.props.updateCurrentNode(this.props.node)
    }

    onChevronClick(event)
    {
        this.props.onClick()
    }

    isLink(node)
    {
        return this.isLeaf(node) && node.subLevel.isLink
    }

    isLeaf(node)
    {
        return node.levelType === 'leaf'
    }

    isArticle(node)
    {
        return this.isLeaf(node) && node.subLevel.isArticle
    }

    isLoaded(node)
    {
        return node.isLoaded || this.isLeaf(node)
    }

    isActive(node)
    {
        if(! node || ! this.props.currentNode)
        {
            return false
        }

        return this.props.currentNode.id === node.id
    }

    componentDidUpdate(prevProps, prevState)
    {
        if(this.props.currentNode !== prevProps.currentNode && this.isActive(this.props.node))
        {
            this.nodeElement.current.scrollIntoView({
                block: 'center',
            })
        }
    }

    componentDidMount()
    {
        if(this.isActive(this.props.node))
        {
            this.nodeElement.current.scrollIntoView({
                block: 'center',
            })
        }

        if(this.articleCount.current)
        {
            this.articleCountWidth = this.articleCount.current.getBoundingClientRect().width
        }
    }

    render()
    {
        const {
            node,
            childNodes,
            depth,
            isCurrentNodeDisplayed,
            areChildNodesDisplayed,
            t
        } = this.props

        return (
            <li
                className={classNames(
                    {'hidden': ! isCurrentNodeDisplayed},
                    {'open': areChildNodesDisplayed},
                )}
                onClick={this.onLineClick}
            >
                <div
                    className={classNames(
                        'node',
                        {'leaf': this.isLeaf(node)},
                        {'isLoaded': this.isLoaded(node)},
                        {'active': this.isActive(node)},
                        {'isLink': this.isLink(node)},
                        {'article': this.isArticle(node)}
                    )}
                    aria-current={this.isActive(node) ? 'page' : false}
                    ref={this.nodeElement}
                    style={{
                        paddingLeft: depth * 20,
                        paddingRight: this.articleCountWidth
                    }}
                >
                    <i onClick={this.onChevronClick} aria-hidden="true"></i>
                    <a>{node.title}</a>
                    {
                        node.articleCount > 0 &&
                        <span
                            ref={this.articleCount}
                            className={'articleCount'}
                        >
                            {t('classificationBrowsing:tree.node.articlesCount', {count: node.articleCount})}
                        </span>
                    }
                </div>
                {this.renderChildren(childNodes)}
            </li>
        )
    }
}

export default withTranslation()(Node)
