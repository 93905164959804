import { notification } from '../../common/js/notification'
import { Alert } from './Alert.jsx'

let captcha

export const ajaxForm = {

    init() {
        this.initAjaxForm()
        this.initCaptcha()
    },

    initAjaxForm() {
        $('form[data-ajax]').submit(function(e)
        {
            e.preventDefault()
            const form = e.currentTarget

            $.ajax({
                data: $(form).serialize(),
                method: $(form).attr('method'),
                url: $(form).attr("action"),
            })
                .done((response) => {
                    notification.success(Alert, response.message)
                    form.reset()
                    if(captcha)
                    {
                        captcha.reset($(form).find('.captcha-id').val())
                    }
                })
                .fail((response) => {
                    notification.error(Alert, response.responseJSON.message)
                })
        })
    },

    loadHCaptcha() {
        captcha = hcaptcha

        $('.hcaptcha').each((index, element) => {
            const captchaId = captcha.render(
                $(element).attr('data-target'),
                { 'sitekey': $(element).attr('data-sitekey') }
            )

            $(element).parents('form').find('.captcha-id').val(captchaId)
        })
    },

    loadReCaptcha() {
        captcha = grecaptcha

        $('.recaptcha').each((index, element) => {
            const captchaId = captcha.render(
                $(element).attr('data-target'),
                { 'sitekey': $(element).attr('data-sitekey') }
            )

            $(element).parents('form').find('.captcha-id').val(captchaId)
        })
    },

    initCaptcha()  {
        const lang = $('html').attr('lang')
        const captchaName = $('head').data('captcha');

        if(captchaName === 'hcaptcha')
        {
            window.loadCaptcha = ajaxForm.loadHCaptcha
            $.getScript("//js.hcaptcha.com/1/api.js?onload=loadCaptcha&render=explicit&hl=" + lang)
        }

        if(captchaName === 'recaptcha')
        {
            window.loadCaptcha = ajaxForm.loadReCaptcha
            $.getScript("//www.google.com/recaptcha/api.js?onload=loadCaptcha&render=explicit&hl=" + lang)
        }
    },
}
