import React, { Component } from 'react'
import PropTypes from 'prop-types'
import superagent from 'superagent'
import { TreeNodeEntity } from "../entities/TreeNodeEntity"
import { TreeBrowser } from "./TreeBrowser.jsx"

export class ClassificationPlan extends Component
{
    static propTypes = {
        url: PropTypes.string.isRequired,
        currentNodeId: PropTypes.string.isRequired,
        pxIndentSize: PropTypes.number.isRequired,
        pxPaddingSize: PropTypes.number.isRequired,
    }

    rootNode

    constructor(props)
    {
        super(props)
        this.state = { tree: null, currentNodeId: props.currentNodeId }

        this.updateTree = this.updateTree.bind(this)
        this.updateCurrentNode = this.updateCurrentNode.bind(this)
        this.onBranchClick = this.onBranchClick.bind(this)
    }

    componentDidMount()
    {
        this.loadTree()
    }

    loadTree()
    {
        superagent.get(this.props.url)
            .set('X-Requested-With', 'XMLHttpRequest')
            .then((res) => {
                this.rootNode = new TreeNodeEntity(res.body)
                this.updateTree()
            })
    }

    render()
    {
        const { tree, currentNodeId } = this.state
        const { pxIndentSize, pxPaddingSize } = this.props

        if(! tree)
        {
            return null
        }

        return <TreeBrowser
            tree={tree}
            currentNodeId={currentNodeId}
            pxIndentSize={pxIndentSize}
            pxPaddingSize={pxPaddingSize}
            onBranchClick={this.onBranchClick}
            onLeafClick={this.updateCurrentNode}
        />
    }

    updateTree()
    {
        this.setState({ tree: this.rootNode })
    }

    updateCurrentNode(treeNode)
    {
        history.replaceState({}, treeNode.title, treeNode.data.url)
        document.title = treeNode.title

        this.setState({ currentNodeId: treeNode.id })

        document.dispatchEvent(new CustomEvent('updateCurrentNode', {
            detail: {
                contentUrl: treeNode.data.contentUrl,
            },
        }))
    }

    onBranchClick(treeNode)
    {
        if(treeNode.data.isRoot())
        {
            this.updateCurrentNode(treeNode)

            return
        }

        treeNode.data.toggleOpen(this.updateTree)
    }
}
