export const maintenance = {

    init: function()
    {
        this.addClickEventOnMaintenanceAcceptInfoButton()
        this.displayMaintenanceInfo()
    },

    displayMaintenanceInfo: function()
    {
        $('#maintenance-infos').show();
    },

    addClickEventOnMaintenanceAcceptInfoButton: function()
    {
        $('#maintenance-infos-accept').click(function(e){
            $("#maintenance-infos").slideUp()
        })
    },
}
