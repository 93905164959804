import React from 'react'
import ReactDOM from "react-dom"
import Gallery from "./components/Gallery.jsx"

const renderGallery = (element) =>
{
    ReactDOM.render(
        <Gallery
            images={JSON.parse(element.dataset['images'])}
        />,
        element,
    )
}

$(function()
{
    $('.gallery-container').each(function(index, element)
    {
        renderGallery(element)
    })
})
