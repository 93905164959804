import ContentLine from "../components/Branch/ContentLine.jsx"
import ReadMore from "../../readMore/ReadMore.jsx"
import ReactHtmlParser from "react-html-parser"
import MediaList from "../components/Media/List.jsx"
import React from "react"
import i18n from '../i18n'


export const createContentLines = function(branchContentType, branchContent)
{
    let contentLines = []

    switch(branchContentType)
    {
        case 'classification_scheme':
            contentLines = classificationSchemeLines(branchContent)
            break
        case 'archival':
            contentLines = archivalLines(branchContent)
            break
        case 'bibliographic':
            contentLines = bibliographicLines(branchContent)
            break
    }

    return contentLines.filter(contentLine => contentLine !== null)
}

const classificationSchemeLines = function(branchContent)
{
    const {
        referenceCodes: {
            main: mainReferenceCode,
            others: othersReferenceCodes,
        },
        dates,
        organizationResponsibleForIntellectualAccess,
        biographicalHistoryList,
        immediateSourceOfAcquisitionList,
        scopeAndContentList,
        conditionsGoverningAccessList,
        relatedUnitsOfDescriptionList,
        otherFindAidMediaUrl,
    } = branchContent

    return [
        string('mainReferenceCode', mainReferenceCode),
        stringList('othersReferenceCodes', othersReferenceCodes),
        stringList('dates', dates),
        string('organizationResponsibleForIntellectualAccess', organizationResponsibleForIntellectualAccess),
        htmlList('biographicalHistory', biographicalHistoryList),
        htmlList('immediateSourceOfAcquisition', immediateSourceOfAcquisitionList),
        htmlList('scopeAndContent', scopeAndContentList),
        htmlList('conditionsGoverningAccess', conditionsGoverningAccessList),
        htmlList('relatedUnitsOfDescription', relatedUnitsOfDescriptionList),
        mediaList('otherFindAidMedia', otherFindAidMediaUrl)
    ]
}

const archivalLines = function(branchContent)
{
    const {
        referenceCodes: {
            main: mainReferenceCode,
            others: othersReferenceCodes,
        },
        dates,
        organizationResponsibleForIntellectualAccess,
        biographicalHistoryList,
        immediateSourceOfAcquisitionList,
        scopeAndContentList,
        conditionsGoverningAccessList,
        relatedUnitsOfDescriptionList,
        findingAidUrl,
        articleUrl
    } = branchContent

    return [
        string('mainReferenceCode', mainReferenceCode),
        stringList('othersReferenceCodes', othersReferenceCodes),
        stringList('dates', dates),
        string('organizationResponsibleForIntellectualAccess', organizationResponsibleForIntellectualAccess),
        htmlList('biographicalHistory', biographicalHistoryList),
        htmlList('immediateSourceOfAcquisition', immediateSourceOfAcquisitionList),
        htmlList('scopeAndContent', scopeAndContentList),
        htmlList('conditionsGoverningAccess', conditionsGoverningAccessList),
        htmlList('relatedUnitsOfDescription', relatedUnitsOfDescriptionList),
        url('findingAidUrl', findingAidUrl),
        url('articleUrl', articleUrl)
    ]
}

const bibliographicLines = function(branchContent)
{
    const {
        referenceCodes: {
            main: mainReferenceCode,
            others: othersReferenceCodes,
        },
        dates,
        titleProper,
        controlledAccessAuthorList,
        publisher,
        publicationDates,
        findingAidUrl,
        articleUrl
    } = branchContent

    return [
        string('mainReferenceCode', mainReferenceCode),
        stringList('othersReferenceCodes', othersReferenceCodes),
        stringList('dates', dates),
        string('titleProper', titleProper),
        stringList('controlledAccessAuthor', controlledAccessAuthorList),
        string('publisher', publisher),
        stringList('publicationDates', publicationDates),
        url('findingAidUrl', findingAidUrl),
        url('articleUrl', articleUrl)
    ]
}

const string = function(key, string)
{
    if(string === null || string === '')
    {
        return null
    }

    return <ContentLine key={key} label={i18n.t('classificationBrowsing:branch.levels.level.content.parts.' + key)}>
        {string}
    </ContentLine>
}

const stringList = function(key, strings)
{
    if(! Array.isArray(strings) || strings.length <= 0)
    {
        return null
    }

    return string(key, strings.join(', '))
}

const html = function(key, html)
{
    if(html === null || html === '')
    {
        return null
    }

    return <ContentLine key={key} label={i18n.t('classificationBrowsing:branch.levels.level.content.parts.' + key)}>
        <ReadMore height={60}>
            {ReactHtmlParser(html)}
        </ReadMore>
    </ContentLine>
}

const htmlList = function(key, list)
{
    if(! Array.isArray(list) || list.length <= 0)
    {
        return null
    }

    return html(key, list.join(''))
}

const url = function(key, url)
{
    if(url === null)
    {
        return null
    }

    return <ContentLine key={key} label={''}>
        <a className={'target-blank'} href={url} target={'blank'}>
            {i18n.t('classificationBrowsing:branch.levels.level.content.parts.' + key)}
        </a>
    </ContentLine>
}

const mediaList = function(key, url)
{
    if(url === null)
    {
        return null
    }

    return <ContentLine key={key} label={i18n.t('classificationBrowsing:branch.levels.level.content.parts.' + key)}>
        <MediaList mediaUrl={url}/>
    </ContentLine>
}
