import React, { Component } from 'react'
import PropTypes from 'prop-types'
import loader from 'hoc-react-loader'
import {Bar} from 'react-chartjs-2'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import Loading from './Loading.jsx'

class RecentIndexationsPerDay extends Component
{
    static propTypes = {
        data: PropTypes.object.isRequired,
        t: PropTypes.func.isRequired,
    }

    constructor(props)
    {
        super(props)

        this.graphsConfig = {
            'average': {
                type: 'line',
                color: '#35686C',
            },
            'user': {
                type: 'bar',
                color: '#61B9BF',
            },
        }

        this.maxTick = 1;
    }

    buildDatasets()
    {
        const datasets = []
        for(let [graphName, config] of Object.entries(this.graphsConfig))
        {
            datasets.push({
                type: config.type,
                label: this.props.t(`stats:graph.recentIndexationsPerDay.labels.${graphName}`),
                data: this.buildDataset(graphName),
                fill: false,
                backgroundColor: config.color,
                borderColor: config.color,
                yAxisID: `y-axis-${graphName}`
            })
        }

        return {
            datasets
        }
    }

    buildDataset(graphName)
    {
        const dataset = []
        for(let [date, indexationCount] of Object.entries(this.props.data[graphName]))
        {
            this.maxTick = Math.max(indexationCount, this.maxTick)
            dataset.push(indexationCount)
        }

        return dataset
    }

    buildLabels()
    {
        const labels = [];
        for(let [date, indexationCount] of Object.entries(this.props.data['user']))
        {
            labels.push(moment(date).format(this.props.t('stats:graph.dateFormat')))
        }

        return labels
    }

    buildOptions()
    {
        return {
            responsive: true,
            legend: {
                display: false
            },
            scales: {
                xAxes: [
                    {
                        labels: this.buildLabels(),
                    }
                ],
                yAxes: [
                    {
                        ticks: {
                            max: this.maxTick,
                            min: 0
                        },
                        display: false,
                        id: 'y-axis-user',
                    },
                    {
                        ticks: {
                            max: this.maxTick,
                            min: 0
                        },
                        id: 'y-axis-average',
                    }
                ]
            }
        }
    }

    render()
    {
        return (
            <div>
                <Bar
                    data={this.buildDatasets()}
                    options={this.buildOptions()}
                />
            </div>
        )
    }
}

export const LoadedRecentIndexationsPerDay = loader({ print: ['data'], LoadingIndicator: Loading})(RecentIndexationsPerDay)

export default withTranslation()(LoadedRecentIndexationsPerDay)
