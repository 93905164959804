import ReactDOM from 'react-dom'
import React from 'react'
import { Switch } from './forms/Switch.jsx'

const franceConnect = {

    init: function()
    {
        this.initRegistrationSwitch()
    },

    initRegistrationSwitch: function()
    {
        const switchElement = $('#registrationSwitch').get(0)
        if(! (switchElement instanceof HTMLElement))
        {
            return
        }

        const {
            checkedLabel,
            uncheckedLabel,
            switchEventName,
        } = switchElement.dataset

        document.addEventListener(switchEventName, (event) => {
            this.displayRegistrationForms(event.detail.checked)
        })

        ReactDOM.render(
            <Switch
                checkedLabel={checkedLabel}
                uncheckedLabel={uncheckedLabel}
                switchEventName={switchEventName}
            />,
            switchElement
        )

        $('#franceConnectRegistration').show()
    },

    displayRegistrationForms: function(registrationSwitchChecked)
    {
        $('#registrationAssociate, #registrationCreate').hide()
        if(registrationSwitchChecked === false)
        {
            $('#registrationAssociate').show()

            return
        }

        $('#registrationCreate').show()
    }
}

$(function(){
    franceConnect.init()
})
