export class LeafData
{
    url
    contentUrl

    constructor(data)
    {
        this.url = data.url
        this.contentUrl = data.contentUrl
    }
}
