import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { TreeNodeEntity } from "../entities/TreeNodeEntity"

export class LeafNode extends Component
{
    static propTypes = {
        treeNode: PropTypes.instanceOf(TreeNodeEntity).isRequired,
        style: PropTypes.object.isRequired,
        isCurrent: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
    }

    render()
    {
        const { treeNode, style, isCurrent, onClick } = this.props

        return (
            <li style={style} className={classNames('leaf', { current: isCurrent })}>
                <a onClick={() => onClick(treeNode)} title={treeNode.title}>
                    {treeNode.title}
                </a>
            </li>
        )
    }
}
