import Leaflet from 'leaflet'
import 'regenerator-runtime/runtime'
import 'leaflet.markercluster'
import marker from 'leaflet/dist/images/marker-icon.png'
import marker2x from 'leaflet/dist/images/marker-icon-2x.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'
import { reservations } from '../reservation'

export class Map
{
    constructor(mapContainer, documentsUrl, documentUrl, localeSearchInArea, localeCenterMap)
    {
        this.webpackHack()

        this.documentsUrl = documentsUrl
        this.documentUrl =  documentUrl
        this.documents =  []
        this.markers = []
        this.map = null
        this.mapContainer = mapContainer
        this.mapBounds = null
        this.searchInAreaButton = null
        this.centerMapButton = null
        this.localeSearchInArea = localeSearchInArea
        this.localeCenterMap = localeCenterMap
    }

    async load()
    {
        this.loading()
        await this.loadDocuments()
        if(! this.hasDocuments())
        {
            document.dispatchEvent(new Event('noDocumentsFound'))

            return
        }

        document.dispatchEvent(new Event('documentsFound'))

        this.createMap()
        this.addMarkersToMap()
        this.centerMap()
        this.clearControls()
    }

    async reloadFromBounds(bounds)
    {
        this.createMap()
        this.updateMapBounds(bounds)
        this.reload()
    }

    async reload()
    {
        this.loading()
        this.updateMapBounds()
        await this.loadDocuments()
        this.addMarkersToMap()
    }

    async reloadWithoutMapBounds()
    {
        this.loading()
        this.resetMapBounds()
        await this.loadDocuments()
        this.addMarkersToMap()
        this.centerMap()
    }

    loading()
    {
        document.dispatchEvent(new Event('markersLoading'))
    }

    loaded()
    {
        document.dispatchEvent(new Event('markersLoaded'))
    }

    resetMapBounds()
    {
        this.mapBounds = null
    }

    updateMapBounds(bounds)
    {
        if(bounds)
        {
            this.map.fitBounds(bounds)
            this.mapBounds = bounds

            return
        }

        this.mapBounds =[
            [this.map.getBounds().getNorthWest().lat, this.map.getBounds().getNorthWest().lng],
            [this.map.getBounds().getSouthEast().lat, this.map.getBounds().getSouthEast().lng],
        ]

        document.dispatchEvent(new CustomEvent('updateMapBounds', {
            detail: {
                mapBounds: this.mapBounds,
            }
        }))
    }

    createTopRightButton(action, label, title, classNames)
    {
        const topRightButton = Leaflet.Control.extend({
            options: {
                position: 'topright'
            },
            onAdd: () => {
                var container = Leaflet.DomUtil.create('div', `leaflet-bar leaflet-control leaflet-control-custom ${classNames}`)
                container.setAttribute('title', title)
                container.addEventListener('click', action)
                container.innerHTML = label

                return container
            },
        })

        return new topRightButton()
    }

    createSearchInAreaButton()
    {
        this.searchInAreaButton = this.createTopRightButton(() => this.reload(), this.localeSearchInArea, this.localeSearchInArea, 'leaflet-control-search-in-area')
    }

    createCenterMapButton()
    {
        this.centerMapButton = this.createTopRightButton(() => this.reloadWithoutMapBounds(), '<i class="nf nf-center-map" aria-hidden="true"></i>', this.localeCenterMap, 'leaflet-control-center-map')
    }

    centerMap()
    {
        if(this.mapBounds)
        {
            this.map.fitBounds(this.mapBounds)
        }
        const group = new Leaflet.featureGroup(this.markers)

        this.map.fitBounds(group.getBounds())

        this.updateMapBounds()
    }

    resetLayer()
    {
        this.map.eachLayer((layer) => {
            this.map.removeLayer(layer)
        })

        const layer = Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png')
        layer.on('load', () => {
            this.loaded()
        })
        layer.addTo(this.map)
    }

    addMarkersToMap()
    {
        this.loadMarkers()

        if(! this.hasDocuments() || this.map === null) return

        const markersCluster = Leaflet.markerClusterGroup()

        this.markers.forEach(marker => {
            markersCluster.addLayer(marker);
        })

        this.map.addLayer(markersCluster)
    }

    createMap()
    {
        if(this.map !== null) return

        this.map = Leaflet.map(this.mapContainer, { minZoom: 3, attributionControl: false })
        this.map.on('dragstart', () => {
            this.map.addControl(this.searchInAreaButton)
        })
        this.map.on('zoomstart', () => {
            this.map.addControl(this.searchInAreaButton)
        })

        this.createSearchInAreaButton()
        this.createCenterMapButton()
    }

    clearControls()
    {
        this.map.removeControl(this.searchInAreaButton)
        this.map.removeControl(this.centerMapButton)
    }

    hasDocuments()
    {
        return Array.isArray(this.documents) && this.documents.length !== 0
    }

    loadMarkers()
    {
        this.resetLayer()

        this.markers = []
        this.clearControls()

        if(! this.hasDocuments())
        {
            this.map.addControl(this.centerMapButton)
            return
        }

        this.documents.forEach(document => {

            const recordUrlWithParams = this.documentUrl
                                        .replace('@type@',document.type)
                                        .replace('@uuid@', document.id)

            let marker = new Leaflet.marker(document.geolocation).openPopup()
                .on({
                        click: async () => {
                            if(! marker.getPopup())
                            {
                                marker.on('popupopen', function() {
                                    reservations.init()
                                })
                                return marker.bindPopup(await this.loadDocument(recordUrlWithParams)).openPopup()
                            }

                            return marker.getPopup().togglePopup()
                        }
                    }
                )
            this.markers.push(marker)
        })
    }

    async loadDocuments()
    {
        const ajaxParams = {
            url: this.documentsUrl,
        }

        if(this.isMapBoundsValid()) {
            ajaxParams.data = {
                searchBounds : {
                    northWest: {
                        lat: this.mapBounds[0][0],
                        lon: this.mapBounds[0][1]
                    },
                    southEast: {
                        lat: this.mapBounds[1][0],
                        lon: this.mapBounds[1][1]
                    }
                }
            }
        }

        this.documents =  await $.ajax(ajaxParams)

        document.dispatchEvent(new CustomEvent('documentsLoaded', {
            detail: {
                documents: this.documents,
            }
        }))
    }

    isMapBoundsValid()
    {
        return Array.isArray(this.mapBounds)
            && this.mapBounds.length === 2
            && this.isCoordinatesValid(this.mapBounds[0])
            && this.isCoordinatesValid(this.mapBounds[1])
    }

    isCoordinatesValid(coordinates)
    {
        return Array.isArray(coordinates)
            && coordinates.length === 2
    }

    loadDocument(url)
    {
        return $.ajax({
            url,
            data: {
                mode: "map"
            }
        })
    }

    webpackHack()
    {
        /**
         * Hack to deal with icons loading badly implemented by Leaflet for webpack integration
         */
        delete Leaflet.Icon.Default.prototype._getIconUrl
        Leaflet.Icon.Default.mergeOptions({
            iconRetinaUrl: marker2x,
            iconUrl: marker,
            shadowUrl: markerShadow
        })
    }
}
