import React from 'react'
import ReactDOM from 'react-dom'
import { ClassificationPlan } from "./components/ClassificationPlan.jsx"
import { notice } from "../../notice"
import { renderer } from "../../renderer"
import { addActionbar } from "../../actionbar"

export const initClassificationPlan = function(element) {
    if(! element)
    {
        return
    }

    const {
        url,
        currentNodeId,
        recordContentTarget,
    } = element.dataset

    const pxIndentSize = getComputedStyle(element).getPropertyValue("--px-indent-size")
    const pxPaddingSize = getComputedStyle(element).getPropertyValue("--px-padding-size")

    document.addEventListener('updateCurrentNode', (e) => updateRecordContent(e.detail.contentUrl, recordContentTarget))

    ReactDOM.render(<ClassificationPlan
        url={url}
        currentNodeId={currentNodeId}
        pxIndentSize={parseInt(pxIndentSize)}
        pxPaddingSize={parseInt(pxPaddingSize)}
    />, element)
}

function updateRecordContent(contentUrl, target)
{
    $.ajax({
        url: contentUrl,
    }).done(function(response) {
        $('#' + target).html(response)

        notice.addClickEventOnCollapseAllNoticeDetailButton()
        notice.initCollapse()

        addActionbar(document.getElementById('actionbar'))

        $('html, body').animate({ scrollTop: 0 }, 'fast')
        renderer.scrollUp()
    })
}
