import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DoughnutGraph from './DoughnutGraph.jsx'

export default class FormFillingPercentage extends Component
{
    static propTypes = {
        data: PropTypes.object,
    }

    constructor(props)
    {
        super(props)

        this.graphsConfig = {
            'complete': {
                color: '#35686C',
                label: 'stats:graph.formFillingPercentage.labels.complete',
            },
            'uncomplete': {
                color: '#61B9BF',
                label: 'stats:graph.formFillingPercentage.labels.uncomplete',
            },
        }
    }

    render()
    {
        return (
            <div>
                <DoughnutGraph config={this.graphsConfig} {...this.props} />
                <div className={'formFillingLabel'}>
                    {
                        this.props.data && `${this.props.data['complete'].toFixed(2)}%`
                    }
                </div>
            </div>
        )
    }
}
