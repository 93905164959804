import { initClassificationSchemeBrowser } from './node/'
import { addActionbar } from './actionbar'
import { oldBrowsers } from './oldBrowsers'
import { rgpd } from './rgpd'
import { maintenance } from './maintenance'
import { renderer } from './renderer'
import { ajaxForm } from './ajaxForm'
import { debounce } from 'debounce'

function initLayout()
{
    initClassificationSchemeBrowser(document.getElementById('classification-scheme-node-list'))

    addActionbar(document.getElementById('actionbar'))
}

$(window)
    .resize(debounce(() => renderer.rebuild(), 200))
    .scroll(() => renderer.onScroll())

$(function() {
    oldBrowsers.init()
    rgpd.init()
    maintenance.init()
    renderer.init()
    ajaxForm.init()

    initLayout()
})

