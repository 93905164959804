import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Level from './Level.jsx'
import { withTranslation } from 'react-i18next'

export class HiddenLevels extends Component
{
    static propTypes = {
        levels: PropTypes.array.isRequired,
        onLevelClick: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
    }

    constructor(props)
    {
        super(props)

        this.onClick = this.onClick.bind(this)
        this.open = this.open.bind(this)

        this.state = {
            isOpen: false
        }
    }

    onClick()
    {
        if(!this.state.isOpen)
        {
            this.open()
        }
    }

    open()
    {
        this.setState({isOpen: true})

        document.addEventListener('click', () => this.setState({isOpen: false}), {once: true})
    }

    render()
    {
        const { levels, onLevelClick, t } = this.props

        return (
            <li
                className={classNames('breadcrumb-level breadcrumb-hidden-levels', {'active': this.state.isOpen})}
            >
                <span
                    id={'hidden-levels-button'}
                    onClick={this.onClick}
                    title={t('classificationBrowsing:breadcrumb.hiddenLevels.label')}
                />
                <div className={classNames(
                    'hidden-levels',
                    {'open': this.state.isOpen}
                )}>
                {levels.map(level => {
                    return <Level
                        onClick={() => onLevelClick(level)}
                        title={level.title}
                        key={level.id}
                    />
                })}
                </div>
            </li>
        )
    }
}

export default withTranslation()(HiddenLevels)
