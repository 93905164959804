(() => {
    try
    {
        document.body.querySelector(':focus-visible')
    }
    catch(error)
    {
        return import('focus-visible/dist/focus-visible.min.js')
    }
})()
