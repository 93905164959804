import { stateToHTML } from 'draft-js-export-html'
import { convertFromRaw } from 'draft-js'

$(function ()
{
    $('[data-richtext]').each(function() {
        const richTextContent = $(this).data('richtext')
        const contentState = convertFromRaw(richTextContent)
        const options = initializeOptions($(this).data('richtextcolor'))

        $(this).after(stateToHTML(contentState, options))
    })

    $('a.external-link').each(function() {
        $(this).append(
            $("<i>").addClass("fa fa-external-link icon-external-link").attr("aria-hidden", "true")
        )
    })
})

function isExternalLink(url)
{
    const host = extractHost(url) ?? window.location.host

    return host !== window.location.host
}

function extractHost(url)
{
    try {
        return new URL(url).host
    } catch {
        return undefined
    }
}

function initializeOptions(color)
{
    return {
        blockStyleFn: (block) => {
            let attributes = {
                class: block.getData().get('align')
            }
            if(color !== undefined)
            {
                attributes.style =  "color: " + color
            }

            return {
                attributes: attributes
            }
        },
        entityStyleFn: (entity) => {
            const entityType = entity.get('type').toLowerCase();
            if (entityType === 'link') {
                const data = entity.getData()

                return getLinkElement(data)
            }
        },
        blockRenderers: {
            unstyled: (block) => {
                if(block.getText() === '')
                {
                    return '<span class="linebreak" aria-hidden="true"></span>'
                }
            },
        },
    }
}

function getLinkElement(data)
{
    const externalLink = isExternalLink(data.target)

    return {
        element: 'a',
        attributes: {
            href: data.target,
            rel: (data.openInNewWindow === true) ? 'noopener noreferrer' : null,
            target: (data.openInNewWindow === true) ? '_blank' : '_self',
            title: data.title,
            class: externalLink === true ? 'external-link' : "internal-link"
        },
    }
}
