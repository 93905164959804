import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import superagent from 'superagent'
import { notification } from '../../../../common/js/notification'
import { Alert } from '../../Alert.jsx'
import classNames from 'classnames'

class Bookmark extends Component
{
    static propTypes = {
        bookmarkUrl: PropTypes.string.isRequired,
        bookmarkData: PropTypes.object.isRequired,
        bookmarkListUrl: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
        loggedIn: PropTypes.bool.isRequired,
        bookmarked: PropTypes.bool.isRequired,
        maintenance: PropTypes.bool.isRequired,
    }

    constructor(props)
    {
        super(props)

        this.state = { bookmarked: this.props.bookmarked }
        this.bookmark = this.bookmark.bind(this)
    }

    async bookmark()
    {
        const { bookmarkUrl, t, loggedIn, bookmarkListUrl, bookmarkData } = this.props

        if(! loggedIn) return

        let httpRequest = superagent
            .post(bookmarkUrl)

        if(this.state.bookmarked) {
            httpRequest = superagent
                .delete(bookmarkUrl)
        }

        await httpRequest
            .type('form')
            .set('X-Requested-With', 'XMLHttpRequest')
            .send(bookmarkData)
            .then((res) => {
                const { uuid } = res.body
                let message = <div>
                    {t('actionbar:bookmark.create.success')}{". "}
                    <a href={bookmarkListUrl}>{t('actionbar:bookmark.list')}</a>
                </div>

                if(this.state.bookmarked) {
                    message = t('actionbar:bookmark.delete.success')
                }

                notification.success(Alert, message)
                this.toggleBookmark()
            })
            .catch((err) => {
                let message = t('actionbar:bookmark.create.error')
                if(this.state.bookmarked) {
                    message = t('actionbar:bookmark.delete.error')
                }

                notification.error(Alert, message)
            })
    }

    toggleBookmark()
    {
        this.setState({
            bookmarked: ! this.state.bookmarked,
        })
    }

    render()
    {
        const { loggedIn, t, maintenance } = this.props
        const { bookmarked } = this.state

        let title = 'actionbar:bookmark.create.title'
        if(bookmarked) {
            title = 'actionbar:bookmark.delete.title'
        }

        return (
            <button
                className={classNames('actionbar-action', { 'disabled': ! loggedIn || maintenance }, { 'bookmarked': bookmarked })}
                onClick={this.bookmark}
            >
                <i
                    title={t(title)}
                    aria-label={t(title)}
                    className={'fa fa-star'}
                />
                <span className="sr-only">{t(title)}</span>
            </button>
        )
    }
}

export default withTranslation()(Bookmark)
