const editorial = {

    init: function()
    {
        this.addClickEventOnDuplicateFieldButton()
        this.addClickEventOnPublicationTile()
    },

    enableDuplicatedFieldButton: function(addButtonElement, referenceCodeForm)
    {
        const fieldCount = Number.parseInt(referenceCodeForm.find('input.reference-code').length)
        const fieldLimit = Number.parseInt(referenceCodeForm.attr('data-reference-codes-number'))

        addButtonElement.prop('disabled', false)
        addButtonElement.attr('data-original-title', referenceCodeForm.attr('data-btn-add-title'))

        if(fieldCount >= fieldLimit)
        {
            addButtonElement.prop('disabled', true)
            addButtonElement.attr('data-original-title', referenceCodeForm.attr('data-btn-add-limit-reached-title'))
            addButtonElement.tooltip('hide')
        }
    },

    addClickEventOnPublicationTile: function()
    {
        $('.publication-tile').click(function(e){
            window.open($(e.currentTarget).data('target'))
        })
    },

    addClickEventOnDuplicateFieldButton: function()
    {
        $('.duplicate-field').click(function(e) {
            const addButtonElement = $(e.currentTarget)
            const referenceCodeForm = addButtonElement.parents('.form-reference-codes')
            const fieldToDuplicate = referenceCodeForm.find('#' + addButtonElement.data('rel'))
            const duplicatedField = fieldToDuplicate.clone().removeAttr('id').val('')
            const removeDuplicatedFieldButton = document.createElement("button")

            $(removeDuplicatedFieldButton)
                .addClass('btn btn-sm btn-danger section-delete-button')
                .tooltip({title: referenceCodeForm.attr('data-btn-remove-title'), placement: 'right'})
                .click(function(e){
                    $(e.currentTarget).tooltip('hide')
                    $(e.currentTarget).parent().remove()
                    this.enableDuplicatedFieldButton(addButtonElement, referenceCodeForm)
                }.bind(this))

            const buttonContent = document.createElement("i")
            $(buttonContent)
                .addClass('fa fa-trash')
                .appendTo(removeDuplicatedFieldButton)

            const duplicatedFieldContainer = document.createElement('div')
            $(duplicatedFieldContainer).addClass('duplicated-field')

            duplicatedField
                .appendTo(referenceCodeForm)
                .appendTo(duplicatedFieldContainer)

            $(removeDuplicatedFieldButton).appendTo(duplicatedFieldContainer)
            $(duplicatedFieldContainer).appendTo(referenceCodeForm)

            this.enableDuplicatedFieldButton(addButtonElement, referenceCodeForm)
        }.bind(this))
    },
}

editorial.init()
