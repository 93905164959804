import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Arrows extends Component
{
    static propTypes = {
        onClickLeft: PropTypes.func.isRequired,
        onClickRight: PropTypes.func.isRequired,
        images: PropTypes.array.isRequired,
    }

    render()
    {
        const { onClickLeft, onClickRight, images } = this.props

        if(images.length === 1)
        {
            return null
        }

        return (
            <React.Fragment>
                <button className="arrow-navigation previous" onClick={onClickLeft}><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                <button className="arrow-navigation next" onClick={onClickRight}><i className="fa fa-chevron-right" aria-hidden="true"></i></button>
            </React.Fragment>
        )
    }
}

export default Arrows
