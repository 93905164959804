import React, { Component } from 'react'
import PropTypes from 'prop-types'
import loader from 'hoc-react-loader'
import { withTranslation } from 'react-i18next'
import {Doughnut} from 'react-chartjs-2'
import Loading from './Loading.jsx'

class DoughnutGraph extends Component
{
    static propTypes = {
        data: PropTypes.object.isRequired,
        config: PropTypes.object.isRequired,
        t: PropTypes.func.isRequired,
    }

    buildDatasets()
    {
        const labels = []
        const values = []
        const colors = []
        for(let [name, config] of Object.entries(this.props.config))
        {
            labels.push(this.props.t(config.label))
            values.push(this.props.data[name].toFixed(2))
            colors.push(config.color)
        }

        return {
            labels,
            datasets: [{
                data: values,
                backgroundColor: colors,
            }]
        }
    }

    buildOptions()
    {
        return {
            legend: {
                display: false
            }
        }
    }

    render()
    {
        return (
            <div>
                <Doughnut
                    data={this.buildDatasets()}
                    options={this.buildOptions()}
                />
            </div>
        )
    }
}

const LoadedDoughnutGraph = loader({ print: ['data'], LoadingIndicator: Loading})(DoughnutGraph)

export default withTranslation()(LoadedDoughnutGraph)
