import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Loading from './Loading.jsx'

class Indicator extends Component
{
    static propTypes = {
        data: PropTypes.number,
    }

    render()
    {
        return (
            this.props.data === null ? <Loading/> : this.props.data
        )
    }
}

export default Indicator
