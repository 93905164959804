import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { socialUrl } from '../helpers/social'
import { SocialShare } from './SocialShare.jsx'
import Bookmark from './Bookmark.jsx'
import Print from './Print.jsx'
import Mail from './Mail.jsx'
import CopyUrl from './CopyUrl.jsx'

class Actionbar extends Component
{
    static propTypes = {
        bookmarkUrl: PropTypes.string,
        bookmarkData: PropTypes.object,
        bookmarkListUrl: PropTypes.string,
        t: PropTypes.func.isRequired,
        loggedIn: PropTypes.bool.isRequired,
        autoDisplay: PropTypes.bool.isRequired,
        social: PropTypes.bool.isRequired,
        print: PropTypes.bool.isRequired,
        bookmarked: PropTypes.bool.isRequired,
        siteName: PropTypes.string.isRequired,
        pageTitle: PropTypes.string.isRequired,
        shareMailBody: PropTypes.string.isRequired,
        maintenance: PropTypes.bool.isRequired,
    }

    constructor(props)
    {
        super(props)

        this.state = { displayActionbar: this.props.autoDisplay }
        this.toggle = this.toggle.bind(this)
        this.socialActions = this.socialActions.bind(this)
        this.print = this.print.bind(this)
    }


    toggle()
    {
        this.setState({
            displayActionbar: ! this.state.displayActionbar,
        })
    }

    print()
    {
        if(this.props.print) {
            return <Print key={"print"}/>
        }

        return null
    }

    bookmark()
    {
        const { bookmarkUrl, bookmarkData, bookmarked, bookmarkListUrl, loggedIn, maintenance } = this.props

        if(bookmarkUrl) {
            return <Bookmark key="bookmark" bookmarkUrl={bookmarkUrl}
                             bookmarkData={bookmarkData}
                             bookmarkListUrl={bookmarkListUrl}
                             bookmarked={bookmarked}
                             loggedIn={loggedIn}
                             maintenance={maintenance}/>
        }

        return undefined
    }

    socialActions()
    {
        const { social, t } = this.props
        if(social) {
            return [
                <SocialShare key={'linkedIn'} type={'linkedin'} icon={'linkedin-in'} url={socialUrl.linkedin()}
                             title={t('actionbar:socialActions.linkedin')}/>,
                <SocialShare key={'twitter'} type={'twitter'} icon={'x-twitter'} url={socialUrl.twitter()}
                             title={t('actionbar:socialActions.twitter')}/>,
                <SocialShare key={'facebook'} type={'facebook'} icon={'facebook-f'} url={socialUrl.facebook()}
                             title={t('actionbar:socialActions.facebook')}/>,
            ]
        }

        return []
    }

    render()
    {
        const { pageTitle, siteName, shareMailBody, t } = this.props
        const actions = [
            this.print(),
            this.bookmark(),
            <CopyUrl key={"copyUrl"}/>,
            <Mail key={"mail"} pageTitle={pageTitle} siteName={siteName} shareMailBody={shareMailBody}/>,
            ...this.socialActions()
        ]

        return (
            <div className={classNames('actionbar-container', 'no-print')}>
                <button className={classNames('actionbar-toggle', { 'active': this.state.displayActionbar })}
                        aria-haspopup={true} aria-expanded={this.state.displayActionbar} onClick={this.toggle} title={t('actionbar:title')}>
                    <div className="point" />
                    <div className="point" />
                    <div className="point" />
                </button>
                <ul className={classNames('actionbar-actions', { 'hidden': ! this.state.displayActionbar })}>
                    {actions.map((action ) => action !== undefined ? <li key={action.key}>{action}</li> : '')}
                </ul>
            </div>
        )
    }
}

export default withTranslation()(Actionbar)
