import React, { Component } from 'react'
import Node from '../Node.jsx'
import PropTypes from 'prop-types'

export class Leaf extends Component
{
    static propTypes = {
        node: PropTypes.object,
        depth: PropTypes.number.isRequired,
        updateCurrentNode: PropTypes.func.isRequired,
        currentNode: PropTypes.object,
        isCurrentNodeDisplayed: PropTypes.bool.isRequired,
        goToParentNode: PropTypes.func.isRequired,
        goToPreviousNode: PropTypes.func.isRequired,
        goToNextNode: PropTypes.func.isRequired,
        nextNode: PropTypes.object,
    }

    constructor(props)
    {
        super(props)

        this.onKeyDown = this.onKeyDown.bind(this)
    }

    onClick()
    {

    }

    componentWillMount() {
        document.addEventListener("keydown", this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown);
    }

    onKeyDown(event)
    {
        const {node, currentNode, goToNextNode, goToPreviousNode, goToParentNode} = this.props

        if(! currentNode || currentNode.id !== node.id)
        {
            return
        }

        if(['ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft', 'Enter'].includes(event.key))
        {
            event.stopImmediatePropagation()
            event.preventDefault()

            if(event.repeat)
            {
                return
            }
        }

        switch(event.key)
        {
            case 'ArrowUp':
                goToPreviousNode()
                break
            case 'ArrowDown':
            case 'ArrowRight':
                goToNextNode()
                break
            case 'ArrowLeft':
                goToParentNode()
        }
    }

    render()
    {
        const {
            node,
            nextNode,
            depth,
            updateCurrentNode,
            currentNode,
            isCurrentNodeDisplayed,
        } = this.props

        node.lastDescendent = node

        return <Node
            node={node}
            nextNode={nextNode}
            depth={depth}
            updateCurrentNode={updateCurrentNode}
            isCurrentNodeDisplayed={isCurrentNodeDisplayed}
            currentNode={currentNode}
            onClick={this.onClick}
            key={node.id + '-Leaf'}
        />
    }
}
