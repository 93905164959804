export const socialUrl = {
    currentUrl: function() {
        return encodeURIComponent(window.location.href)
    },

    facebook: function() {
        return `https://facebook.com/sharer/sharer.php?u=${this.currentUrl()}`
    },
    
    twitter: function() {
        return `https://twitter.com/intent/tweet/?url=${this.currentUrl()}`
    },

    linkedin: function() {
        return `https://www.linkedin.com/shareArticle?mini=true&url=${this.currentUrl()}&source=${this.currentUrl()}`
    }
}
