import React, { Component } from 'react'
import PropTypes from "prop-types";
import classNames from "classnames";

class Indicators extends Component
{
    static propTypes = {
        current: PropTypes.number.isRequired,
        images: PropTypes.array.isRequired,
        onClick: PropTypes.func,
    }

    render()
    {
        const { current, onClick, images } = this.props
        const total = images.length
        const dots = []

        for(let i = 0; i < total; i++)
        {
            dots.push(
                <button className={classNames("gallery-dot", { "current": i === current })} key={i} onClick={() => onClick(i)}/>
            )
        }

        return (
            <div className="gallery-indicators">
                {dots}
            </div>
        )
    }
}

export default Indicators
