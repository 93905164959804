import moment from 'moment'
import _ from 'lodash'

export class TimelineEventsFactory
{
    constructor(thumbnails)
    {
        this.thumbnails = {}
        if(thumbnails)
        {
            this.thumbnails = thumbnails
        }
    }

    create(data)
    {
        if(! Array.isArray(data) || ! data.length)
        {
            return
        }

        const events = []
        data.forEach(function(rawEvent)
        {
            const event = this.buildEvent(rawEvent)
            if(event)
            {
                events.push(event)
            }
        }, this)

        return events
    }

    buildEvent(rawEvent)
    {
        if(
            ! _.has(rawEvent, 'period.boundaries')
            || ! Array.isArray(rawEvent.period.boundaries)
        )
        {
            return
        }

        const date = moment(rawEvent.period.boundaries[0])

        const event = {
            'start_date' : {
                'month': date.format('M'),
                'day': date.format('D'),
                'year': date.format('YYYY')
            },
            'text': {
                'text': ' ',
                'headline': rawEvent.title,
            },
            'unique_id': rawEvent.documentId,
        }

        if(this.thumbnails[rawEvent.thumbnail] !== undefined)
        {
            event.media = {
                thumbnail: this.thumbnails[rawEvent.thumbnail]
            }
        }

        return event
    }
}
