import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Title extends Component
{
    static propTypes = {
        images: PropTypes.array.isRequired,
        current: PropTypes.number.isRequired,
    }

    constructor(props)
    {
        super(props)

        this.displayTitleElement = this.props.images.find(image => !! image.title)
    }

    render()
    {
        const { images, current } = this.props
        const image = images[current]

        if(! this.displayTitleElement)
        {
            return null
        }

        return (
            <div className="caption-wrapper">
                {image.title && <div className="caption" id={image.id} title={image.title}>{image.title}</div>}
            </div>
        )
    }
}

export default Title
