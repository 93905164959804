import { TreeNodeEntity } from "./TreeNodeEntity"
import superagent from 'superagent'

export class BranchData
{
    childrenUrl
    children
    depth
    isLoaded
    isOpen
    url
    contentUrl

    constructor(data, depth)
    {
        this.childrenUrl = data.childrenUrl
        this.depth = depth
        this.children = this.createChildren(data.children)
        this.isLoaded = data.isLoaded
        this.isOpen = this.isLoaded
        this.url = data.url
        this.contentUrl = data.contentUrl
    }

    isRoot()
    {
        return this.depth === 0
    }

    toggleOpen(onFinish)
    {
        if (this.isOpen || this.isLoaded)
        {
            this.isOpen = ! this.isOpen
            onFinish()

            return
        }

         this.loadChildren(onFinish)
    }

    loadChildren(onFinish)
    {
        superagent.get(this.childrenUrl)
            .set('X-Requested-With', 'XMLHttpRequest')
            .then((res) => {
                this.children = this.createChildren(res.body)
                this.isOpen = true
                this.isLoaded = true
                onFinish()
            })
            .catch(function(err) {
                console.log(err)
            })
    }

    createChildren(children)
    {
        const depth = this.depth + 1
        const childEntities = []

        children.forEach(function(child) {
            childEntities.push(new TreeNodeEntity(child, depth))
        })

        return childEntities
    }
}
