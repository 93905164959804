import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import {debounce} from 'lodash'

class Button extends Component
{
    static propType = {
        t: PropTypes.func.isRequired,
        footerHeight: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired,
        isOpen: PropTypes.func.isRequired,
        isDisplayable: PropTypes.func.isRequired,
    }

    constructor(props)
    {
        super(props)

        this.onClick = this.onClick.bind(this)
        this.bottomPosition = this.bottomPosition.bind(this)
        this.handleWindowResize = this.handleWindowResize.bind(this)
        this.onWindowResize = this.onWindowResize.bind(this)

        this.state = {
            bottomPosition: this.bottomPosition(props.isOpen()),
            isOpen: props.isOpen(),
            isDisplayable: props.isDisplayable(),
        }
    }

    bottomPosition(isOpen)
    {
        let bottomPosition = 0
        if(isOpen)
        {
            bottomPosition = this.props.footerHeight()
        }

        return bottomPosition
    }

    onClick()
    {
        this.props.onClick()

        this.setState({
            bottomPosition: this.bottomPosition(this.props.isOpen()),
            isOpen: this.props.isOpen()
        })
    }

    onWindowResize()
    {
        this.setState({
            bottomPosition: this.bottomPosition(this.props.isOpen()),
            isDisplayable: this.props.isDisplayable(),
        })
    }

    handleWindowResize()
    {
        window.addEventListener('resize', debounce(this.onWindowResize, 500))
    }

    componentDidMount()
    {
        this.handleWindowResize()
    }

    componentWillUnmount()
    {
        window.removeEventListener('resize', debounce(this.onWindowResize, 500))
    }

    render()
    {
        if(! this.state.isDisplayable)
        {
            return null
        }

        let buttonLabel = 'show'
        if(this.state.isOpen)
        {
            buttonLabel = 'hide'
        }

        return (
            <div
                id="footer-hide-button"
                className={classNames({'open': this.state.isOpen})}
                style={{bottom: this.state.bottomPosition}}
                onClick={this.onClick}
                title={this.props.t(`classificationBrowsing:footer.button.${buttonLabel}`)}
            ></div>
        )
    }
}

export default withTranslation()(Button)

