import { clipboard } from '../../common/js/clipboard'
import { notification } from '../../common/js/notification'
import { Alert } from './Alert.jsx'

$(function(){
    clipboard.init('[data-copy-clipboard]', function(event) {

        event.preventDefault()

        if(! event.currentTarget.dataset.alertMessage)
        {
            return
        }

        notification.success(Alert, event.currentTarget.dataset.alertMessage)
    })
})
