import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

class Print extends Component
{
    static propTypes = {
        t: PropTypes.func.isRequired,
    }

    render()
    {
        const { t } = this.props

        return (
            <button className={'actionbar-action'} key="print" onClick={window.print}>
                <i
                    title={t('actionbar:print.title')}
                    aria-label={t('actionbar:print.title')}
                    className={'fa fa-print'}
                ></i>
                <span className="sr-only">{t('actionbar:print.title')}</span>
            </button>
        )
    }
}

export default withTranslation()(Print)
