require('jquery-ui/ui/widgets/sortable')
import i18n from './i18n'
import { notification } from '../../../common/js/notification'
import { Alert } from '../Alert.jsx'
import React from 'react'

export const reservations = {
    init: function() {
        this.bindAddRecordToCart()
        this.bindRemoveSelectedArticlesFromCart()
        this.bindReserveSelectedArticles()
        this.bindRemoveArticleFromDemand()
        this.initSortableArticles()
        this.bindViewComment()
    },
    bindAddRecordToCart: function() {
        $(".add-record-to-cart").on("submit", (event) => {
            event.preventDefault()
            this.addRecord(event.currentTarget)
        })
    },
    addRecord: function(form) {
        const { cartUrl } = form.dataset

        if(! cartUrl)
        {
            return
        }

        $.ajax({
            data: $(form).serialize(),
            method: 'POST',
            url: form.action,
        })
            .done(function(response) {
                let message = <div>
                    {i18n.t('reservation:cart.add.success')} <a href={cartUrl}>{i18n.t('reservation:cart.view')}</a>
                </div>

                notification.success(Alert, message)
            })
            .fail(function(response){
                let message = i18n.t('reservation:cart.add.error')
                if(response.responseJSON.message)
                {
                    message = response.responseJSON.message
                }
                notification.error(Alert, message)
            })
    },
    bindRemoveSelectedArticlesFromCart: function()
    {
        $('#removeSelectedArticles').on("submit", (event) => {
            event.preventDefault()
            $("input[name='articles[]']").attr("form", "removeSelectedArticles")
            event.currentTarget.submit()
        })
    },
    bindReserveSelectedArticles: function()
    {
        $('#reserveSelectedArticles').on("submit", (event) => {
            event.preventDefault()
            $("input[name='articles[]']").attr("form", "reserveSelectedArticles")
            event.currentTarget.submit()
        })
    },
    bindRemoveArticleFromDemand: function()
    {
        $('.remove-article-from-demand').on("click", (event) => {
            const articleUuid = $(event.currentTarget).data("article")

            $(`tr[data-article="${articleUuid}"]`).remove()
        })
    },
    initSortableArticles: function()
    {
        $(".sortable-articles").sortable({
            items: "tbody > tr",
            handle: ".sort-button",
            cancel: "",
            helper: function(event, sortedElement) {
                const columns = sortedElement.children()
                const helper = sortedElement.clone()
                helper.children().each(function(index)
                {
                    $(this).width(columns.eq(index).outerWidth())
                })

                return helper
            },
            start: function(event, ui ){
                ui.placeholder.height(ui.helper.outerHeight());
            },
        })
    },
    bindViewComment: function()
    {
        const viewCommentElement = $(".view-comment")
        viewCommentElement.popover({
            trigger: "manual",
            container: $(".reservations"),
            html: true,
            template: $("#popover-template").html(),
        })

        viewCommentElement.on("click", function(event) {
            event.preventDefault()
            viewCommentElement.not($(this))
                .removeClass("active")
                .popover("hide")

            $(this)
                .toggleClass("active")
                .popover("toggle")
        })

        viewCommentElement.on("shown.bs.popover", function(event) {
            const currentEl = $(this)
            const popoverId = currentEl.attr("aria-describedby")
            $("a", `#${popoverId}`).on("click", function() {
                currentEl.popover("hide")
                viewCommentElement.removeClass("active")
            })
        })
    }
}

$(function() {
    reservations.init()
})
