export default class responsiveMode
{
    static COMFORT =  30
    static ESSENTIAL =  20
    static INDISPENSABLE =  10

    static ESSENTIAL_RESOLUTION = 768
    static COMFORT_RESOLUTION = 1200

    responsiveLevel = null

    constructor(responsiveLevel)
    {
        this.responsiveLevel = responsiveLevel
    }

    level()
    {
        return this.responsiveLevel
    }

    static comfort()
    {
        return new responsiveMode(responsiveMode.COMFORT)
    }

    static essential()
    {
        return new responsiveMode(responsiveMode.ESSENTIAL)
    }

    static indispensable()
    {
        return new responsiveMode(responsiveMode.INDISPENSABLE)
    }

    static isComfort(resolution)
    {
        return resolution >= responsiveMode.COMFORT_RESOLUTION
    }

    static isEssential(resolution)
    {
        return resolution < responsiveMode.COMFORT_RESOLUTION
            && resolution >= responsiveMode.ESSENTIAL_RESOLUTION
    }

    static isIndispensable(resolution)
    {
        return resolution < responsiveMode.ESSENTIAL_RESOLUTION
    }

    atLeast(responsiveMode)
    {
        return this.level() >= responsiveMode.level()
    }
}
