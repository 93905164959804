import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Type extends Component
{
    static propType = {
        id: PropTypes.string.isRequired,
    }

    constructor(props)
    {
        super(props)
        this.typeIcon = this.typeIcon.bind(this)
    }

    typeIcon()
    {
        switch(this.props.id)
        {
            case 'video': return 'play-circle-o'
            case 'document': return 'file-pdf-o'
            case 'audio': return 'volume-up'
            case 'image': return 'picture-o'
        }

        return 'file-o'
    }

    render()
    {
        return <i className={`media-type fa fa-${this.typeIcon()}`} aria-hidden="true"> </i>
    }
}
