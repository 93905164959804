import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Node from '../Node.jsx'
import superagent from "superagent"

export class Parent extends Component
{
    static propTypes = {
        node: PropTypes.object,
        depth: PropTypes.number.isRequired,
        updateCurrentNode: PropTypes.func.isRequired,
        updateParentLastDescendent: PropTypes.func.isRequired,
        goToParentNode: PropTypes.func.isRequired,
        goToPreviousNode: PropTypes.func.isRequired,
        goToNextNode: PropTypes.func.isRequired,
        currentNode: PropTypes.object,
        nextNode: PropTypes.object,
        isCurrentNodeDisplayed: PropTypes.bool.isRequired,
        areChildNodesDisplayed: PropTypes.bool.isRequired,
    }

    constructor(props)
    {
        super(props)

        if (props.node.levelType === 'standaloneParent')
        {
            this.state = {
                childrenDisplayed: false,
                childNodes: null,
                previousChildrenUrl: null,
                nextChildrenUrl: null,
                lastDescendent: this.props.node,
            }
        }
        else
        {
            const childNodes = props.node.subLevel.childrenBatch.children

            this.state = {
                childrenDisplayed: props.areChildNodesDisplayed,
                childNodes,
                previousChildrenUrl: props.node.subLevel.childrenBatch.previousBatch,
                nextChildrenUrl: props.node.subLevel.childrenBatch.nextBatch,
                lastDescendent: childNodes[childNodes.length - 1]
            }

            this.props.updateParentLastDescendent(childNodes[childNodes.length - 1])
        }

        this.onClick = this.onClick.bind(this)
        this.onKeyDown = this.onKeyDown.bind(this)
        this.updateLastDescendent = this.updateLastDescendent.bind(this)
        this.toggleChildren = this.toggleChildren.bind(this)
        this.open = this.open.bind(this)
        this.close = this.close.bind(this)
        this.onPrevious = this.onPrevious.bind(this)
        this.onNext = this.onNext.bind(this)
    }


    componentWillMount() {
        document.addEventListener("keydown", this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown);
    }

    async onKeyDown(event)
    {
        const {updateCurrentNode, node, currentNode, goToNextNode, goToPreviousNode, goToParentNode} = this.props
        const {childrenDisplayed, childNodes} = this.state

        if(! currentNode || currentNode.id !== node.id)
        {
            return
        }

        if(['ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft', 'Enter'].includes(event.key))
        {
            event.stopImmediatePropagation()
            event.preventDefault()

            if(event.repeat)
            {
                return
            }
        }

        switch(event.key)
        {
            case 'ArrowUp':
                goToPreviousNode()
                break
            case 'ArrowDown':
                childrenDisplayed ? updateCurrentNode(childNodes[0]) : goToNextNode()
                break
            case 'ArrowRight':
                childrenDisplayed ? updateCurrentNode(childNodes[0]) : await this.open()
                break
            case 'ArrowLeft':
                childrenDisplayed ? this.close() : goToParentNode()
                break
            case 'Enter':
                await this.toggleChildren()
        }
    }

    async onClick()
    {
        await this.toggleChildren()
    }


    async toggleChildren()
    {
        this.state.childrenDisplayed ? this.close() : await this.open()
    }

    async open()
    {
        let nextChildrenUrl = this.state.nextChildrenUrl
        let childNodes = this.state.childNodes
        if(childNodes === null)
        {
            this.setState({
                childNodes: []
            })

            await superagent
                .get(this.props.node.subLevel.childrenUrl)
                .then((res) => {
                    childNodes = res.body.children
                    nextChildrenUrl = res.body.nextBatch
                })
        }

        this.setState({
            childrenDisplayed: true,
            childNodes,
            nextChildrenUrl,
        })

        this.updateLastDescendent(this.closedLastDescendant || childNodes[childNodes.length - 1])
    }

    close()
    {
        this.setState({
            childrenDisplayed: false,
        })
        this.closedLastDescendant = this.state.lastDescendent
        this.updateLastDescendent(this.props.node)
    }

    async onNext()
    {
        if(! this.state.nextChildrenUrl)
        {
            return
        }

        let newCurrentNode
        let childNodes = this.state.childNodes
        let nextChildrenUrl = this.state.nextChildrenUrl

        this.setState({
            nextChildrenUrl: null
        })

        await superagent
            .get(nextChildrenUrl)
            .then((res) => {
                childNodes = childNodes.concat(res.body.children)
                nextChildrenUrl = res.body.nextBatch
                newCurrentNode = res.body.children[0]
            })

        this.setState({
            childNodes,
            nextChildrenUrl,
        })
        this.updateLastDescendent(childNodes[childNodes.length - 1])
        this.props.updateCurrentNode(newCurrentNode)
    }

    async onPrevious()
    {
        if(! this.state.previousChildrenUrl)
        {
            return
        }

        let newCurrentNode
        let childNodes = this.state.childNodes
        let previousChildrenUrl = this.state.previousChildrenUrl

        this.setState({
            previousChildrenUrl: null
        })

        await superagent
            .get(previousChildrenUrl)
            .then((res) => {
                childNodes = res.body.children.concat(childNodes)
                previousChildrenUrl = res.body.previousBatch
                newCurrentNode = res.body.children[res.body.children.length - 1]
            })

        this.setState({
            childNodes,
            previousChildrenUrl
        })
        this.props.updateCurrentNode(newCurrentNode)
    }

    updateLastDescendent(lastDescendent)
    {
        this.setState({
            lastDescendent
        })

        this.props.updateParentLastDescendent(lastDescendent)
    }

    render()
    {
        const {
            node,
            nextNode,
            depth,
            updateCurrentNode,
            currentNode,
            isCurrentNodeDisplayed
        } = this.props

        const {
            childNodes,
            childrenDisplayed,
            previousChildrenUrl,
            nextChildrenUrl,
            lastDescendent
        } = this.state

        node.lastDescendent = lastDescendent

        return <Node
            node={node}
            nextNode={nextNode}
            childNodes={childNodes}
            hasPrevious={!! previousChildrenUrl}
            onPrevious={this.onPrevious}
            hasNext={!! nextChildrenUrl}
            onNext={this.onNext}
            depth={depth}
            onClick={this.onClick}
            updateCurrentNode={updateCurrentNode}
            updateLastDescendent={this.updateLastDescendent}
            currentNode={currentNode}
            isCurrentNodeDisplayed={isCurrentNodeDisplayed}
            areChildNodesDisplayed={childrenDisplayed}
            key={node.id + '-Parent'}
        />
    }
}
