import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export class Switch extends Component
{
    static propTypes = {
        checkedLabel: PropTypes.string.isRequired,
        uncheckedLabel: PropTypes.string.isRequired,
        switchEventName: PropTypes.string.isRequired,
    }

    constructor(props)
    {
        super(props)

        this.onSwitch = this.onSwitch.bind(this)
        this.dispatchCheckEvent = this.dispatchCheckEvent.bind(this)

        this.state = { checked: true }
    }

    onSwitch()
    {
        this.setState({checked: !this.state.checked})
    }

    dispatchCheckEvent()
    {
        document.dispatchEvent(new CustomEvent(this.props.switchEventName, {
            detail: {
                checked: this.state.checked,
            },
        }))
    }

    componentDidUpdate()
    {
        this.dispatchCheckEvent()
    }

    componentDidMount()
    {
        this.dispatchCheckEvent()
    }

    render()
    {
        const { checkedLabel, uncheckedLabel } = this.props
        const { checked } = this.state

        return (
            <React.Fragment>
                <span className={classNames('option', {'active': ! checked})}>{uncheckedLabel}</span>
                <label className="switch">
                    <input type="checkbox" onChange={this.onSwitch} defaultChecked={checked} />
                    <span className="slider round"></span>
                </label>
                <span className={classNames('option', {'active': checked})}>{checkedLabel}</span>

            </React.Fragment>
        )
    }
}
