import { BranchData } from "./BranchData"
import { LeafData } from "./LeafData"

export class TreeNodeEntity
{
    id
    title
    depth
    data

    constructor(treeNode, depth = 0)
    {
        this.id = treeNode.id
        this.title = treeNode.title
        this.depth = depth
        this.data = TreeNodeEntity.createData(treeNode.dataType, treeNode.data, depth)
    }

    static createData(type, data, depth)
    {
        if (type === 'branch')
        {
            return new BranchData(data, depth)
        }

        if (type === 'leaf')
        {
            return new LeafData(data)
        }

        return null
    }
}
