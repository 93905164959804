import React, { Component } from 'react'
import PropTypes from 'prop-types'

class GalleryImage extends Component
{
    static propTypes = {
        image: PropTypes.object.isRequired,
        preload: PropTypes.array.isRequired,
    }

    render()
    {
        const { image, children, innerRef, preload } = this.props

        let imageAttributes = null
        if(image.title)
        {
            imageAttributes = {'aria-describedby': image.id}
        }

        return (
            <React.Fragment>
                <div className="gallery-image">
                    <a ref={innerRef} rel="noopener noreferrer" target="_blank" href={image.target} tabIndex="-1">
                        <img src={image.src} alt={image.alt} title={image.title} {...imageAttributes}/>
                    </a>
                    {children}
                </div>
                <div style={{display: "none"}}>
                    {preload.map(image => <img key={image.id} src={image.src}/>)}
                </div>
            </React.Fragment>
        )
    }
}

export default React.forwardRef((props, ref) => <GalleryImage innerRef={ref} {...props}/>)
