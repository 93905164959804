import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GalleryImage from './GalleryImage.jsx'
import Indicators from './Navigation/Indicators.jsx'
import Arrows from './Navigation/Arrows.jsx'
import KeyboardGestureNavigation from './Navigation/KeyboardGestureNavigation.jsx'
import Title from './Title.jsx'
import { modulo } from '../utils'
import { uniqWith, isEqual } from 'lodash/array'

class Gallery extends Component
{
    static propTypes = {
        images: PropTypes.array.isRequired,
    }

    constructor(props)
    {
        super(props)

        this.state = { currentIndex: 0 }
        this.imageRef = React.createRef()

        this.updateCurrentIndex = this.updateCurrentIndex.bind(this)
        this.previous = this.previous.bind(this)
        this.next = this.next.bind(this)
    }

    updateCurrentIndex(newIndex)
    {
        this.setState({ currentIndex: modulo(newIndex, this.props.images.length) })
    }

    previous()
    {
        this.updateCurrentIndex(this.state.currentIndex - 1)
    }

    next()
    {
        this.updateCurrentIndex(this.state.currentIndex + 1)
    }

    render()
    {
        const images = this.props.images

        if(images.length === 0)
        {
            return null
        }

        const index = this.state.currentIndex
        const image = images[index]
        const imagesToPreload = uniqWith([
            images[modulo(index - 1, images.length)],
            images[modulo(index + 1, images.length)],
        ], isEqual)

        return (
                <div className="gallery">
                    <KeyboardGestureNavigation previous={this.previous} next={this.next} imageRef={this.imageRef}>
                        <GalleryImage ref={this.imageRef} image={image} preload={imagesToPreload}>
                            <Arrows images={images} onClickLeft={this.previous} onClickRight={this.next}/>
                        </GalleryImage>
                    </KeyboardGestureNavigation>
                    <KeyboardGestureNavigation previous={this.previous} next={this.next} imageRef={this.imageRef}>
                        <Indicators current={index} images={images} onClick={this.updateCurrentIndex}/>
                    </KeyboardGestureNavigation>
                    <Title current={index} images={images}/>
                </div>
        )
    }
}

export default Gallery
