import React, { Component } from 'react'
import PropTypes from 'prop-types'

export class SocialShare extends Component {

    static propTypes = {
        type: PropTypes.oneOf([
            'twitter',
            'facebook',
            'linkedin',
        ]),
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        icon: PropTypes.string
    }

    render() {
        const { type, url, title, icon = type} = this.props
        return (
            <a
                href={url}
                rel="noopener noreferrer"
                target="_blank"
                title={title}
                aria-label={title}
                className={`nf nf-${icon} actionbar-action`}
            >
                <span className="sr-only">{title}</span>
            </a>
        )
    }
}
