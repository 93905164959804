import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from "react-router-dom";
import { withRouter } from 'react-router'
import TreeBrowser from '../../../common/js/treeBrowser/components/TreeBrowser.jsx'
import {LinkData} from "../../../common/js/treeBrowser/helpers/Entities/AdditionalData/LinkData"

const initNodeList = (element, actions, isHistoryAware) => {

    if(element instanceof HTMLElement === false)
    {
        return
    }

    const treeBrowserProps = {
        apiUrl: element.getAttribute('data-api-url'),
        nodeId: element.getAttribute('data-nodeid'),
        titleLabel: element.getAttribute('data-title-label'),
        noDocumentLabel: element.getAttribute('data-no-document-label'),
        actions,
        icons:{
            folder: 'fa-archive',
            file: 'fa-file',
            pdf: 'fa-file-pdf',
        }
    }

    if(
        treeBrowserProps.titleLabel === null
        || treeBrowserProps.noDocumentLabel == null
    ){
        return
    }

    let TreeBrowserComponent = <TreeBrowser {...treeBrowserProps} />

    if(isHistoryAware === true)
    {
        const TreeBrowserComponentWithHistory = withRouter(TreeBrowser)
        TreeBrowserComponent = <Router>
            <Route
                path="/"
                component={() => <TreeBrowserComponentWithHistory {...treeBrowserProps} /> }
            />
        </Router>
    }

    ReactDOM.render(
        TreeBrowserComponent,
        element
    )
}

const onTargetNode = (openInNewFrame) => (node) => {
    const link = node.additionalData

    if(! (link instanceof LinkData))
    {
        return;
    }

    let target = '_self'
    if(link.forceOpenInNewTab === true || openInNewFrame === true)
    {
        target = '_blank'
    }

    window.open(link.url, target)
}

export const initClassificationSchemeBrowser = (element) => {
    return initNodeList(element, { onTargetNode: onTargetNode() }, true)
}
